import UnitTestingInterpretationsService from "@/modules/product/product-hub/product-testing/services/UnitTestingInterpretations.service";
export const SEL_BY_ALL = "ALL";
const DEFAULT_CRITERIA = {
  testingProfileCodeSelection: "",
  unitFrom: "",
  unitTo: "",
  testDateFrom: "",
  testDateTo: "",
  searchTypeChoice: SEL_BY_ALL,
};
export const productTesting = {
  namespaced: true,
  state: {
    currentLocks: [],
    criteria: {
      ...DEFAULT_CRITERIA,
    },
  },

  getters: {
    currentLocks: (state) => {
      return state.currentLocks;
    },
  },
  mutations: {
    setCurrentLocks(state, locks) {
      state.currentLocks = locks;
    },
    setCriteria(state, data) {
      state.criteria = { ...data };
    },
  },
  actions: {
    lockUnits(context, data) {
      return new Promise((resolve, reject) => {
        UnitTestingInterpretationsService.lockDonorTestProfileList(data).then(
          (response) => {
            context.commit("setCurrentLocks", response.data);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    unlockCurrentLocks(context) {
      if (context.state.currentLocks.length > 0) {
        return new Promise((resolve, reject) => {
          UnitTestingInterpretationsService.unlockDonorTestProfileList(
            context.state.currentLocks
          ).then(
            (response) => {
              context.commit("setCurrentLocks", []);
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
    },
    resetState({ commit }) {
      commit("setCurrentLocks", []);
      commit("setCriteria", DEFAULT_CRITERIA);
    },
  },
};
