import Axios from "axios";
const MAIN_PATH = "/collections/api";
const QUESTIONNAIRE = "/questionnaire";
const GET_FOLLOW_UP = "/questionnaire/followup";
const QUESTION_COMMENT = "/appearance/comment";
const QUESTION_TEXT = "/management/question-text/preferred-language";

export default {
  getQuestionnaire(appearanceUuid) {
    return Axios.get(MAIN_PATH + QUESTIONNAIRE, {
      params: {
        appearance: appearanceUuid,
      },
    });
  },

  saveQuestionnaire(appearanceUuid, questionnaire) {
    return Axios.post(MAIN_PATH + QUESTIONNAIRE, questionnaire, {
      params: {
        appearance: appearanceUuid,
      },
    });
  },

  getQuestionText(questionCodes) {
    return Axios.post(QUESTION_TEXT, questionCodes);
  },

  getFollowUps(questions) {
    let params = "?";
    for (let m = 0; m < questions.length; ++m) {
      params += "questions=" + questions[m];
      if (m < questions.length - 1) {
        params += "&";
      }
    }
    return Axios.get(MAIN_PATH + GET_FOLLOW_UP + params);
  },

  getQuestionComments(appearanceUuid, question) {
    return Axios.get(MAIN_PATH + QUESTION_COMMENT, {
      params: {
        appearance: appearanceUuid,
        question: question,
      },
    });
  },

  addQuestionComment(payload) {
    return Axios.post(MAIN_PATH + QUESTION_COMMENT, payload);
  },

  deleteQuestionComment(item) {
    return Axios.delete(MAIN_PATH + QUESTION_COMMENT, {
      headers: {},
      data: item,
    });
  },
};
