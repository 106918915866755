import Vue from "vue";
import Vuex from "vuex";
import { auth } from "@/modules/main/store/auth.module";
import { reqFunc } from "@/modules/main/store/req.func";
import { whldonor } from "@/modules/donor/demographic/store/whldonor.module";
import { whlsecurity } from "@/modules/main/store/whlsecurity.module";
import { leftnav } from "@/modules/main/store/nav.store";
import { whlqpass } from "@/modules/donor/quickpass/store/whlqpass.module";
import { whldonscrn } from "@/modules/donor/screening/store/whldonscrn.module";
import { whlphlebot } from "@/modules/donor/phlebotomy/store/whlphlebot.module";
import { whlmodreg } from "@/modules/donor/registration-modification/store/whlmodreg.module";
import { whlreg } from "@/modules/donor/registration/store/whlreg.module";
import { DonorManagement } from "@/modules/donor/management/store/DonorManagement.module";
import { fielderr } from "@/modules/donor/donor-hub/store/fielderror.module";
import { productStore } from "@/modules/product/store/products.module";
import { productTesting } from "@/modules/product/product-hub/product-testing/store/ProductTesting.module";
import { questionsModule } from "@/modules/donor/questions/store/questions.module";
import { whlInventory } from "@/modules/inventory/store/whlInventory.module";
import { whlpatient } from "@/modules/patient/patient-hub/store/whlpatient.module";
import { PatientManagement } from "@/modules/patient/management/store/PatientManagement.module";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true, //use in dev only
  modules: {
    auth: auth,
    reqFunc: reqFunc,
    leftnav: leftnav,
    whlreg: whlreg,
    DonorManagement: DonorManagement,
    whldonor: whldonor,
    whlsecurity: whlsecurity,
    whlqpass: whlqpass,
    whldonscrn: whldonscrn,
    whlphlebot: whlphlebot,
    whlmodreg: whlmodreg,
    fielderr: fielderr,
    productStore: productStore,
    productTesting: productTesting,
    questionsModule: questionsModule,
    whlInventory: whlInventory,
    whlpatient,
    PatientManagement,
  },
});
