import { HelperFunctions, MnemonicService } from "@bbcsinc/common-vue";
import lodash from "lodash";

const MEDICATION = "MEDICATION";
const TRAVEL = "TRAVEL";
const ALLERGY = "ALLERGY";
const HISTORY = "HISTORY";
const THIRD_PARTY = "THIRD_PARTY";
const FOLLOWUPS = "followups";
const NONE_OF_THE_ABOVE = "NONE_OF_THE_ABOVE";
const UPDATE_QUESTIONNAIRE = "updateQuestionnaire";
const SET_LOADING = "setLoading";

export const questionHelper = {
  getFollowupIndex(followups, questionCode) {
    for (let i = 0; i < followups.length; ++i) {
      let followup = followups[i];

      if (followup["question"] === questionCode) {
        return i;
      }
    }
    return -1;
  },

  getChildReference(currentKey, questionKeys, questionRef) {
    let followupsList = questionRef[FOLLOWUPS];
    let index = this.getFollowupIndex(followupsList, currentKey);

    if (index > -1) {
      if (questionKeys.length > 1) {
        return this.getChildReference(
          questionKeys.shift(),
          questionKeys,
          followupsList[index]
        );
      } else {
        return questionRef[FOLLOWUPS][index];
      }
    } else {
      return null;
    }
  },
  getQuestion(questions, questionKeys) {
    questionKeys = JSON.parse(JSON.stringify(questionKeys));
    if (questionKeys.length > 1) {
      let ref = questions[questionKeys.shift()];

      let parent = ref;
      if (questionKeys.length > 1) {
        parent = questionHelper.getChildReference(
          questionKeys.shift(),
          questionKeys,
          ref
        );
      }
      let followupIndex = questionHelper.getFollowupIndex(
        parent.followups,
        questionKeys[0]
      );
      return JSON.parse(JSON.stringify(parent.followups[followupIndex]));
    } else {
      return JSON.parse(JSON.stringify(questions[questionKeys.shift()]));
    }
  },
  updateOccurenceDateForPickAny(questionnaireList, appearanceDate) {
    const questionnaire = lodash.cloneDeep(questionnaireList);
    questionnaire.forEach((question) => {
      switch (question.questionType) {
        case "YES_NO":
          if (question.formPreset) {
            if (question.presetResponse) {
              question.presetResponse.occurrenceDate = appearanceDate;
            }
          } else {
            question.occurrenceDate = appearanceDate;
          }
          break;
        case "PICK_ONE":
          switch (question.formType) {
            case MEDICATION:
              if (question.responseItem) {
                question.responseItem.occurrenceDate =
                  question.formItem.lastDosage;
              }
              break;
            case TRAVEL:
              if (
                question.responseItem &&
                question.responseItem.response !== NONE_OF_THE_ABOVE
              ) {
                question.responseItem.occurrenceDate =
                  question.formItem.endDate;
              }
              break;
            case ALLERGY:
              if (question.responseItem) {
                question.responseItem.occurrenceDate = appearanceDate;
              }
              break;
            case HISTORY:
              if (question.responseItem) {
                question.responseItem.occurrenceDate =
                  question.formItem.occurrenceDate;
              }
              break;
            case THIRD_PARTY:
              if (question.responseItem) {
                question.responseItem.occurrenceDate =
                  question.formItem.donationDate;
              }
              break;
          }
          break;
        case "PICK_ANY":
          switch (question.formType) {
            case MEDICATION:
              if (question.responseItems) {
                question.responseItems.forEach((responseItem) => {
                  var occurDate = questionHelper.findOccurenceDateForMedication(
                    question.formItems,
                    responseItem.response
                  );
                  if (occurDate) {
                    responseItem.occurrenceDate = occurDate;
                  }
                });
              }
              break;
            case TRAVEL:
              if (question.responseItems) {
                question.responseItems.forEach((responseItem) => {
                  var occurDate = questionHelper.findOccurenceDateForTravel(
                    question.formItems,
                    responseItem.response
                  );
                  if (occurDate) {
                    responseItem.occurrenceDate = occurDate;
                  }
                });
              }
              break;
            case ALLERGY:
              if (question.responseItems) {
                question.responseItems.forEach((responseItem) => {
                  var occurDate = questionHelper.findOccurenceDateForAllergy(
                    question.formItems,
                    responseItem.response,
                    appearanceDate
                  );
                  if (occurDate) {
                    responseItem.occurrenceDate = occurDate;
                  }
                });
              }
              break;
            case HISTORY:
              if (question.responseItems) {
                question.responseItems.forEach((responseItem) => {
                  var occurDate = questionHelper.findOccurenceDateForHistory(
                    question.formItems,
                    responseItem.response
                  );
                  if (occurDate) {
                    responseItem.occurrenceDate = occurDate;
                  }
                });
              }
              break;
            case "NO_FORM":
              if (question.responseItems) {
                question.responseItems.forEach((responseItem) => {
                  var occurDate = questionHelper.findOccurenceDateForNoForm(
                    question.formItems,
                    responseItem.response,
                    appearanceDate
                  );
                  if (occurDate) {
                    responseItem.occurrenceDate = occurDate;
                  }
                });
              }
              break;
            case THIRD_PARTY:
              if (question.responseItems) {
                question.responseItems.forEach((responseItem) => {
                  var occurDate = questionHelper.findOccurenceDateForThirdParty(
                    question.formItems,
                    responseItem.response
                  );
                  if (occurDate) {
                    responseItem.occurrenceDate = occurDate;
                  }
                });
              }
              break;
          }
          break;
      }
    });
    return questionnaire;
  },

  // MEDICATION PICK
  findOccurenceDateForMedication(formItems, response) {
    let date = undefined;
    formItems.forEach((formItem) => {
      if (formItem.medication === response) {
        date = formItem.lastDosage;
      }
    });
    return date;
  },
  // TRAVEL
  findOccurenceDateForTravel(formItems, response) {
    let date = undefined;
    formItems.forEach((formItem) => {
      if (formItem.travelLocation === response) {
        date = formItem.endDate;
      }
    });
    return date;
  },
  // ALLERGY
  findOccurenceDateForAllergy(formItems, response, appearanceDate) {
    let date = undefined;
    formItems.forEach((formItem) => {
      if (formItem.alergy === response) {
        date = appearanceDate;
      }
    });
    return date;
  },
  // HISTORY
  findOccurenceDateForHistory(formItems, response) {
    let date = undefined;
    formItems.forEach((formItem) => {
      if (formItem.history === response) {
        date = formItem.occurrenceDate;
      }
    });
    return date;
  },
  // NO FORM
  findOccurenceDateForNoForm(formItems, response, appearanceDate) {
    let date = undefined;
    formItems.forEach((formItem) => {
      if (formItem.noform === response) {
        if (formItem.appearanceDate) {
          date = formItem.appearanceDate;
        } else {
          date = appearanceDate;
        }
      }
    });
    return date;
  },
  // THIRD PARTY
  findOccurenceDateForThirdParty(formItems, response) {
    let date = undefined;
    formItems.forEach((formItem) => {
      if (formItem.thirdParty === response) {
        date = formItem.donationDate;
      }
    });
    return date;
  },
};

import QuestionService from "@/modules/donor/questions/services/Questions.service.js";
import HealthRecordService from "@/modules/donor/history/services/HealthRecord.service";

export const questionsModule = {
  namespaced: true,

  state: {
    primaryQuestions: {
      questionID: {},
      question2ID: {},
    },
    appearanceUUID: null,
    loading: false,
    healthRecords: {}, // needed to resolve health record types
    mnemonics: {},
    isReadOnly: false,
  },
  getters: {
    getQuestionnaire(state) {
      return state.primaryQuestions;
    },
    getQuestionnaireList(state) {
      return Object.values(state.primaryQuestions);
    },
    loading(state) {
      return state.loading;
    },
    appearanceUUID(state) {
      return state.appearanceUUID;
    },
    isReadOnly(state) {
      return state.isReadOnly;
    },
    getHealthRecords(state) {
      return state.healthRecords;
    },
    getMnemonics: (state) => (mnemonicType) => {
      return state[mnemonicType];
    },
    getMnemonicsByFormType: (state) => (formType) => {
      switch (formType) {
        case TRAVEL:
          return state.mnemonics[MnemonicService.types.TRAVEL_DESTINATIONS];
        case ALLERGY:
          return state.mnemonics[MnemonicService.types.ALLERGIES];
        case MEDICATION:
          return state.mnemonics[MnemonicService.types.MEDICATIONS];
        case THIRD_PARTY:
          return state.mnemonics[
            MnemonicService.types.THIRD_PARTY_BLOOD_DRAW_LOCATION
          ];
        case HISTORY:
          return state.mnemonics[MnemonicService.types.HEALTH_RECORD];
        default:
          return state.mnemonics[
            MnemonicService.types.QUESTION_MULTIPLE_CHOICE
          ];
      }
    },
    // resolves mnemonics with the corresponding mnemonic map and the NONE_OF_THE_ABOVE mnemonic
    // use in PICK_ONE
    getResponseDisplayValue: (state, getters) => (code, formType) => {
      const noneOfTheAboveMnemonic =
        state.mnemonics[MnemonicService.types.QUESTION_MULTIPLE_CHOICE][
          NONE_OF_THE_ABOVE
        ];

      if (code === NONE_OF_THE_ABOVE) {
        if (noneOfTheAboveMnemonic) {
          return noneOfTheAboveMnemonic.displayValue;
        } else {
          return NONE_OF_THE_ABOVE;
        }
      }

      const mnemonicMap = getters.getMnemonicsByFormType(formType);
      return HelperFunctions.getDisplayValue(mnemonicMap, code);
    },
  },
  actions: {
    updateQuestion(state, questionInformation) {
      questionInformation = JSON.parse(JSON.stringify(questionInformation));
      state.commit("updateQuestion", questionInformation);
    },

    initializeQuestionnaire({ commit }, data) {
      commit(UPDATE_QUESTIONNAIRE, data);
    },

    async loadAdditionalData({ commit }) {
      const healthRecordsReq = HealthRecordService.getHealthRecords();
      const mnemonicsReq = MnemonicService.getMultipleMaps(
        MnemonicService.types.QUESTION_MULTIPLE_CHOICE,
        MnemonicService.types.ALLERGIES,
        MnemonicService.types.TRAVEL_DESTINATIONS,
        MnemonicService.types.MEDICATIONS,
        MnemonicService.types.HEALTH_RECORD,
        MnemonicService.types.THIRD_PARTY_BLOOD_DRAW_LOCATION
      );

      try {
        const [healthRecordsResp, mnemonicsResp] = await Promise.all([
          healthRecordsReq,
          mnemonicsReq,
        ]);

        commit(
          "setHealthRecords",
          HelperFunctions.createMap(healthRecordsResp.data, "code")
        );

        let mnemonics = {};
        mnemonics[MnemonicService.types.QUESTION_MULTIPLE_CHOICE] =
          mnemonicsResp[0].data;
        mnemonics[MnemonicService.types.ALLERGIES] = mnemonicsResp[1].data;
        mnemonics[MnemonicService.types.TRAVEL_DESTINATIONS] =
          mnemonicsResp[2].data;
        mnemonics[MnemonicService.types.MEDICATIONS] = mnemonicsResp[3].data;
        mnemonics[MnemonicService.types.HEALTH_RECORD] = mnemonicsResp[4].data;
        mnemonics[MnemonicService.types.THIRD_PARTY_BLOOD_DRAW_LOCATION] =
          mnemonicsResp[5].data;

        commit("setMnemonics", mnemonics);
      } catch (error) {
        //eslint-disable-next-line no-console
        console.error(
          "An error ocurred when loading questionnaire additional responses data.",
          error
        );
      }
    },

    async loadQuestionnaire({ commit, dispatch }, data) {
      commit("setAppearanceUUID", data.appearance);
      commit("setIsReadOnly", data.isReadOnly);
      commit(SET_LOADING, true);
      try {
        dispatch("loadAdditionalData");

        const questionsResp = await QuestionService.getQuestionnaire(
          data.appearance
        );
        const questionIds = questionsResp.data.map(
          (question) => question.question
        );
        const questionTextResp = await QuestionService.getQuestionText(
          questionIds
        );

        const questionsTextMap = HelperFunctions.createMap(
          questionTextResp.data,
          "questionCode"
        );

        const questionsWithText = questionsResp.data.map((question) => ({
          ...question,
          questionText: questionsTextMap[question.question].questionText,
        }));

        commit(UPDATE_QUESTIONNAIRE, questionsWithText);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        commit(SET_LOADING, false);
      }
    },

    async saveQuestionnaire({ commit, state, getters }, appearanceDate) {
      commit(SET_LOADING, true);
      try {
        var questionnaireList = questionHelper.updateOccurenceDateForPickAny(
          getters.getQuestionnaireList,
          appearanceDate
        );
        const questionsResp = await QuestionService.saveQuestionnaire(
          state.appearanceUUID,
          questionnaireList
        );
        return questionsResp;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        commit(SET_LOADING, false);
      }
    },
  },
  mutations: {
    //Last key is the current question you are updating
    updateQuestion(state, params) {
      let questionKeys = params.questionKeys;

      if (questionKeys.length > 1) {
        let ref = state["primaryQuestions"][questionKeys.shift()];

        let parent = ref;
        if (questionKeys.length > 1) {
          parent = questionHelper.getChildReference(
            questionKeys.shift(),
            questionKeys,
            ref
          );
        }
        let followupIndex = questionHelper.getFollowupIndex(
          parent.followups,
          questionKeys[0]
        );
        parent.followups[followupIndex] = params.questionData;
      } else {
        state["primaryQuestions"][questionKeys.shift()] = params.questionData;
      }
    },

    updateQuestionnaire(state, questionnaireList) {
      let temp = {};
      for (let i = 0; i < questionnaireList.length; ++i) {
        let question = questionnaireList[i];
        temp[question["question"]] = question;
      }

      state.primaryQuestions = temp;
    },

    setAppearanceUUID(state, appearanceUUID) {
      state.appearanceUUID = appearanceUUID;
    },
    setIsReadOnly(state, isReadOnly) {
      state.isReadOnly = isReadOnly;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },

    setHealthRecords(state, healthRecords) {
      state.healthRecords = healthRecords;
    },

    setMnemonics(state, mnemonics) {
      state.mnemonics = mnemonics;
    },
  },
};
