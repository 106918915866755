<template>
  <v-row class="pane-container">
    <div
      v-if="hasLeftPane"
      class="pa-0 ma-0 left-pane"
      :style="{
        minHeight: `calc(100vh - ${headerFooterHeight})`,
        maxHeight: `calc(100vh - ${headerFooterHeight})`,
      }"
    >
      <slot name="left-pane" />
    </div>
    <div
      v-if="hasLeftPane"
      class="pa-0 ma-0 pb-10 pb-sm-0 right-pane"
      :style="{ maxHeight: `calc(100vh - ${headerFooterHeight})` }"
    >
      <div v-if="!hideLocation">
        <DonorLocation v-if="donorLocation" class="location" />
        <Location
          v-else
          :selectable="locationSelectable"
          class="location"
          :availableLocations="availableLocations"
        />
      </div>
      <div v-if="hasTopRightCornerSlot" class="top-right-corner">
        <slot name="top-right-corner" />
      </div>
      <div class="pa-2 pa-md-4">
        <slot />
      </div>
      <v-footer padless fixed class="pane-footer">
        <!-- <modals-container v-if="!isModal" v-on:add-error="addError($event)" /> -->
        <ErrorBar v-bind:errors="errors" @override="overrideError" />
      </v-footer>
    </div>
    <v-col
      v-if="!hasLeftPane"
      cols="12"
      class="pa-0 ma-0 right-pane"
      :class="{ 'pb-3': !isModal }"
      :style="{
        maxHeight: !isModal ? `calc(100vh - ${headerFooterHeight})` : '100%',
        height: !isModal ? 'auto' : '100%',
        background: !isModal ? '#f9f9f9' : '#ffffff',
      }"
    >
      <DonorLocation v-if="hasLocation && donorLocation" class="location" />
      <Location
        v-if="hasLocation && !donorLocation"
        :selectable="locationSelectable"
        class="location"
        :availableLocations="availableLocations"
      />
      <slot />
      <v-footer padless fixed class="pane-footer">
        <!-- <modals-container v-if="!isModal" v-on:add-error="addError($event)" /> -->
        <ErrorBar v-bind:errors="errors" @override="overrideError" />
      </v-footer>
    </v-col>
  </v-row>
</template>

<script>
import ErrorBar from "./ErrorBar.vue";
import Location from "./Location.vue";
import DonorLocation from "../../../donor/management/components/DonorLocation.vue";

export default {
  props: [
    "title",
    "hasLocation",
    "donorLocation",
    "showTopButtons",
    "errors",
    "isModal",
    "leftHandPane",
    "noButtons",
    "displayBreadCrumb",
    "showLocation",
    "hideLocation",
    "loc",
    "locationSelectable",
    "availableLocations",
  ],
  name: "ContentPaneV2",
  components: {
    ErrorBar,
    Location,
    DonorLocation,
  },
  data() {
    return {};
  },
  computed: {
    hasLeftPane() {
      return Object.keys(this.$slots).includes("left-pane");
    },
    hasTopRightCornerSlot() {
      return Object.keys(this.$slots).includes("top-right-corner");
    },
    headerFooterHeight() {
      let headerFooterHeight = 0;
      const appHeader = document.getElementById("abo-cloud-header");
      const appFooter = document.getElementById("abo-cloud-footer");
      if (appHeader) {
        headerFooterHeight = headerFooterHeight + appHeader.offsetHeight;
      }
      if (appFooter) {
        headerFooterHeight = headerFooterHeight + appFooter.offsetHeight;
      }
      return headerFooterHeight + "px";
    },
  },
  methods: {
    addError: function ($err) {
      this.errors.push($err);
    },
    overrideError(error) {
      this.$emit("override", error);
    },
  },
};
</script>

<style scoped>
.pane-container {
  margin: 0;
  width: 100%;
  position: relative;
}
.left-pane {
  width: 396px;
  z-index: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: fit-content;
  background-color: #fff;
  box-shadow: 6px -12px 20px 0 rgba(55, 39, 58, 0.06);
}
.right-pane {
  width: calc(100% - 396px);
}
.right-pane {
  overflow-y: auto;
  background-color: #f9f9f9;
}
.location {
  margin-top: 20px;
}
.pane-footer {
  bottom: 40px !important;
}
.left-pane::-webkit-scrollbar-track,
.right-pane::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eef2f5;
}
.left-pane::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  background-color: #d6d6d6;
}
.right-pane::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background-color: #d6d6d6;
}
.left-pane::-webkit-scrollbar-thumb,
.right-pane::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2b518c;
}
.top-right-corner {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
}
@media only screen and (max-width: 1024px) {
  .left-pane {
    width: 264px !important;
  }
  .right-pane {
    width: calc(100% - 264px) !important;
  }
}
@media only screen and (max-width: 600px) {
  .pane-container {
    flex: initial;
  }
  .location {
    top: 0;
    z-index: 2;
    margin-top: 10px;
    position: absolute;
  }
  .top-right-corner {
    top: 0;
    z-index: 2;
    margin-top: 10px;
    position: absolute;
  }
  .left-pane,
  .right-pane {
    width: 100% !important;
    overflow-x: hidden;
    min-height: initial !important;
    max-height: initial !important;
  }
}
</style>
