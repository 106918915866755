<template>
  <v-row no-gutters v-if="!selectable" id="Location-label" class="fieldX">
    <span class="d-flex">
      <v-icon :small="$vuetify.breakpoint.xs">$locationIcon</v-icon>
      <span class="ml-1">
        {{ $helperFunctions.getDisplayValue(locationsMap, selectedLocation) }}
      </span>
    </span>
  </v-row>
  <v-row no-gutters v-else id="location-selector">
    <span class="location-select-span">
      <v-select
        id="location-selector"
        height="52px"
        class="location-selector"
        :items="locations"
        item-text="displayValue"
        item-value="code"
        v-model="selectedLocation"
        solo
        rounded
        dense
        prepend-inner-icon="$locationIcon"
      />
    </span>
  </v-row>
</template>

<script>
import { HelperFunctions, MnemonicService } from "@bbcsinc/common-vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Location",
  props: {
    selectable: Boolean,
    availableLocations: null,
  },

  data() {
    return {
      locations: [],
      locationsMap: {},
    };
  },
  computed: {
    ...mapGetters("productStore", ["getLaboratoryLocation"]),
    selectedLocation: {
      get() {
        return this.getLaboratoryLocation;
      },
      set(value) {
        this.setLaboratoryLocation(value);
      },
    },
  },
  watch: {
    availableLocations: {
      handler(newLocations) {
        this.locations = newLocations;
        this.locationsMap = HelperFunctions.createMnemonicMap(newLocations);
      },
    },
  },
  methods: {
    ...mapMutations("productStore", ["setLaboratoryLocation"]),
  },
  mounted() {
    this.selectedLocation = this.getLaboratoryLocation;

    if (this.availableLocations && Array.isArray(this.availableLocations)) {
      this.locations = this.availableLocations;
      this.locationsMap = HelperFunctions.createMnemonicMap(
        this.availableLocations
      );
      return;
    }

    MnemonicService.get(MnemonicService.types.LABORATORY).then((res) => {
      this.locations = res.data;
      this.locationsMap = HelperFunctions.createMnemonicMap(res.data);
    });
  },
};
</script>

<style scoped>
.fieldX {
  margin-left: -5px;
  position: relative;
  display: inline-block;
  background-color: #eef2f5;
  padding: 12px 33px 12px 22px;
  border-top-right-radius: 27px;
  border-bottom-right-radius: 27px;
  box-shadow: 0 6px 10px 0 rgba(43, 81, 140, 0.1);
}

.selectorX {
  /*width: 234px;*/
  /*display: inline;*/
  /*min-width: 234px;*/
  height: 52px;
  /*margin: 0px 8px 48px 0;*/

  border-radius: 27px;
  border-top-right-radius: 27px;
  border-bottom-right-radius: 27px;
  box-shadow: 0 6px 10px 0 rgba(43, 81, 140, 0.1);
  background-color: #eef2f5;
}

/*.locationX {*/
/*  position: absolute;*/
/*  top: 10px;*/
/*  left: 30px;*/
/*  right:10px;*/

/*  width: 16px;*/
/*  height: 20px;*/
/*  margin: 3.5px 14px 4.5px 0;*/

/*  object-fit: contain;*/
/*}*/

/*.loctext {*/
/*  margin-left:20px;*/
/*  font-size: 20px;*/
/*  color: #2c3e50;*/
/*}*/

@media only screen and (max-width: 600px) {
  .fieldX {
    font-size: 12px;
    padding: 10px 16px 10px 22px;
  }
}

.location-selector {
  margin-left: 40px;
}

.location-select-span {
  max-width: 100%;
}
</style>
