const defaults = {
  lastName: "",
  firstName: "",
  middleName: "",
  nameSuffix: "",
  birthdate: "", //2020-02-28T16:24:42.844+0000
  ssn: "",
  uuid: "",
  patientId: "",
  customerCodes: "",
  searchType: 0,
  customerLocationCodes: "",
  medicalRecordNumber: "",
  admissionNumber: "",
  startPos: 0,
  recSize: 100,
  maxSize: 0,
};

export const whlpatient = {
  namespaced: true,
  state: {
    patientCriteria: defaults,

    origPatientInfo: {},
  },
  actions: {},
  mutations: {
    initPatientData(state, patientData) {
      state.origPatientInfo = { ...state.origPatientInfo, ...patientData };
    },
    updatePatientData(state, patientData) {
      state.origPatientInfo = { ...patientData };
    },

    updatePatientCriteria(state, data) {
      state.patientCriteria = { ...state.patientCriteria, ...data };
    },
    resetPatientCriteria(state) {
      state.patientCriteria = defaults;
    },
  },
};
