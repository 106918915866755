import Axios from "axios";

import qs from "qs";
const PATH = "/collections/api/unit-test-profile";
const SINGLE_TESTING_FOR_INTERPRETATION = "/single-testing-for-interpretation";
const EDIT_UPDATE_TESTING_INTERPRETATION =
  "/edit-update-testing-interpretation";
const UPDATE_TESTING_INTERPRETATION = "/update-testing-interpretation";
const PROFILE_TESTING_FOR_INTERPRETATION =
  "/profile-testing-for-interpretation";
const LOCK_DONOR_TEST_PROFILE = "/lock-donor-test-profile";
const LOCK_DONOR_TEST_PROFILE_LIST = "/lock-donor-test-profile-list";
const UNLOCK_DONOR_TEST_PROFILE = "/unlock-donor-test-profile";
const UNLOCK_DONOR_TEST_PROFILE_LIST = "/unlock-donor-test-profile-list";
const ACTIVE_UNIT_CRITERIA = "collections/api/inventory/active/inquiry";
const CONFIRMATORY = "/confirmatory";
const SUBSEQUENT = "/subsequent";
export default {
  getSingleTestingForInterpretation(params) {
    return Axios.get(PATH + SINGLE_TESTING_FOR_INTERPRETATION, {
      params: {
        testCode: params.testCode,
        units: params.units,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  },
  editUpdateTestingInterpretation(data) {
    return Axios.post(PATH + EDIT_UPDATE_TESTING_INTERPRETATION, data);
  },
  updateTestingInterpretation(data) {
    return Axios.post(PATH + UPDATE_TESTING_INTERPRETATION, data);
  },
  getProfileTestingForInterpretation(params) {
    return Axios.get(PATH + PROFILE_TESTING_FOR_INTERPRETATION, {
      params: {
        profileName: params.profileName,
        units: params.units,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  },
  lockDonorTestProfile(params) {
    return Axios.post(
      PATH + LOCK_DONOR_TEST_PROFILE,
      {},
      {
        params: {
          unit: params.unit,
          product: params.product,
          division: params.division,
          profileName: params.profileName,
        },
      }
    );
  },
  lockDonorTestProfileList(data) {
    return Axios.post(PATH + LOCK_DONOR_TEST_PROFILE_LIST, data);
  },
  unlockDonorTestProfile(params) {
    return Axios.post(
      PATH + UNLOCK_DONOR_TEST_PROFILE,
      {},
      {
        params: {
          unit: params.unit,
          product: params.product,
          division: params.division,
          profileName: params.profileName,
        },
      }
    );
  },
  unlockDonorTestProfileList(data) {
    return Axios.post(PATH + UNLOCK_DONOR_TEST_PROFILE_LIST, data);
  },
  getActiveUnitsForDonorTesting(data) {
    return Axios.post(ACTIVE_UNIT_CRITERIA, data);
  },

  getConfirmatory() {
    return Axios.get(PATH + CONFIRMATORY);
  },

  addUnitTestProfile(data) {
    return Axios.post(PATH + SUBSEQUENT, data);
  },
};
