<template>
  <div>
    <v-row class="d-flex align-center app-bar px-5 px-md-8 px-lg-10" no-gutters>
      <div class="d-flex align-center">
        <img
          src="@/assets/app-header/side-menu.svg"
          id="app_header_leftside_menu"
          class="side-menu"
          @click.stop="drawer = !drawer"
        />

        <v-navigation-drawer
          v-model="drawer"
          absolute
          temporary
          app
          width="396"
        >
          <v-row class="icon-row">
            <v-col
              cols="12"
              class="d-flex pr-8 py-5 d-flex justify-space-between align-center"
            >
              <img
                src="@/assets/app-header/for-life-logo-dark.svg"
                class="left-menu-logo"
                @click="routeClickHandler(mainIconRoute)"
              />
              <common-button
                fab
                text
                icon
                small
                id="app_header_close_menu"
                @click.stop="drawer = !drawer"
              >
                <v-icon size="sm"> mdi-close </v-icon>
              </common-button>
            </v-col>
          </v-row>

          <div class="main-navigation-left">
            <v-row
              id="app_header_options_menu"
              @click="handleOptions"
              class="d-flex align-center px-8 py-5 cursor-pointer"
            >
              <img
                src="@/assets/app-header/left-menu/options.svg"
                class="left-menu-icons"
              />
              <span class="ml-4 text-heading-3 secondary--color">{{
                $t("whlmain.appheader.leftmenu.options")
              }}</span>
            </v-row>
            <v-divider class="ml-5" />
            <!-- <v-row>
              <img src="@/assets/app-header/left-menu/plugins.svg" class="left-menu-icons">
              <span class="text-heading-3 secondary--color" id="left-menu-plugins">{{ $t('whlmain.appheader.leftmenu.plugins') }}</span>
            </v-row>
            <v-divider class="mt-5 ml-5"/>
            <v-row>
              <img src="@/assets/app-header/left-menu/help.svg" class="left-menu-icons">
              <span class="text-heading-3 secondary--color" id="left-menu-help">{{ $t('whlmain.appheader.leftmenu.help') }}</span>
            </v-row>
            <v-divider class="mt-5 ml-5"/> -->
            <v-row
              id="app_header_about_menu"
              @click="handleAbout"
              class="d-flex align-center px-8 py-5 cursor-pointer"
            >
              <img
                src="@/assets/app-header/left-menu/about.svg"
                class="left-menu-icons"
              />
              <span class="ml-4 text-heading-3 secondary--color">{{
                $t("whlmain.appheader.leftmenu.about")
              }}</span>
            </v-row>
            <v-divider />
            <v-list class="d-md-none">
              <div
                v-for="(item, index) in mainNavigationItems"
                :key="'mainNavigationItems-' + item.title + '-' + index"
                class="cursor-pointer ml-5"
                :id="'app_header_mobile_menu-' + index"
              >
                <v-list-item>
                  <v-list-item-title
                    ><h5 class="text--primary">
                      {{ $t("whlmain.appheader." + item.title) }}
                    </h5>
                  </v-list-item-title>
                  <v-list-item-icon
                    :id="'app_header_mobile_menu-' + index"
                    @click="item.toggle = !item.toggle"
                    :class="`normal ${item.toggle ? 'rotate' : 'normal'}`"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-divider class="ml-8 mr-5" />
                <div v-if="item.toggle && item.child.length > 0">
                  <div
                    class="mx-10 py-2"
                    v-for="(childItem, childIndex) in item.child"
                    :key="childIndex"
                  >
                    <v-list-item>
                      <v-list-item-title
                        :id="'app_header_mobile_sub_menu-' + childIndex"
                        @click="itemNavigation(childItem.route)"
                        ><h5 class="text--primary">
                          {{ $t(childItem.title) }}
                        </h5>
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </div>
              </div>
            </v-list>
          </div>
          <v-row
            class="left-menu-logout-row cursor-pointer"
            no-gutters
            id="app_header_logout"
            @click="handleLogOut"
          >
            <v-col cols="12">
              <v-divider />
            </v-col>
            <img
              src="@/assets/app-header/left-menu/logout.svg"
              class="left-menu-icons"
            />
            <span id="left-menu-logout">{{
              $t("whlmain.appheader.leftmenu.logout")
            }}</span>
          </v-row>
        </v-navigation-drawer>

        <div
          class="ml-3 ml-md-5 ml-lg-6 d-flex align-center"
          id="app_header_dashboard_logo"
          @click="routeClickHandler(mainIconRoute)"
        >
          <img
            src="@/assets/app-header/for-life-logo.svg"
            class="navbar-logo cursor-pointer"
          />
        </div>
      </div>
      <div class="d-nav ml-4 ml-sm-5 ml-md-8">
        <span
          class="nav-button text-body-1"
          id="app_header_donors"
          @click="navToDonorHub"
        >
          {{ $t("whlmain.appheader.donors") }}</span
        >

        <span
          class="nav-button text-body-1"
          id="app_header_products"
          @click="routeToProductHub"
        >
          {{ $t("whlmain.appheader.products") }}</span
        >

        <span
          class="nav-button text-body-1"
          id="app_header_inventory"
          @click="navToInventory"
        >
          {{ $t("whlmain.appheader.inventory") }}
        </span>
        <span
          class="nav-button text-body-1"
          id="app_header_patients"
          @click="navToPatientHub"
        >
          {{ $t("whlmain.appheader.patients") }}</span
        >
        <span
          class="nav-button text-body-1"
          id="app_header_quality"
          @click="navToQualityHub"
        >
          {{ $t("whlmain.appheader.quality") }}</span
        >
        <span
          class="nav-button text-body-1"
          id="app_header_billing"
          @click="navToBillingHub"
        >
          {{ $t("whlmain.appheader.billing") }}</span
        >

        <v-menu
          v-model="reportDropDown"
          :close-on-content-click="true"
          offset-x
        >
          <template v-slot:activator="{ on3, attrs3 }">
            <span
              class="nav-button text-body-1"
              id="app_header_reports"
              v-bind="attrs3"
              v-on="on3"
              @click="reportDropDown = !reportDropDown"
            >
              {{ $t("whlmain.appheader.reports") }}
            </span>
          </template>
          <v-card>
            <v-list
              v-for="(item, index) in reportChildren"
              :key="'app_header_reports' + index"
              class="cursor-pointer pt-0 pb-0"
            >
              <div>
                <v-list-item
                  :id="'app_header_report-' + item.id"
                  @click="goToRouteByName(item)"
                  class="text-body-1 font-weight-regular"
                >
                  {{ $t(item.title) }}
                </v-list-item>
                <v-divider />
              </div>
            </v-list>
          </v-card>
        </v-menu>
      </div>
      <div
        id="app_header_user_menu"
        @click="showUserMenu"
        class="ml-auto d-flex align-center cursor-pointer"
      >
        <div class="time-and-name">
          <span class="text-body-1 white--text text-right">
            {{ (fullName() !== "" && fullName()) || username() }}
          </span>
          <span class="text-body-1 white--text" id="appheader-current-time">
            {{ currentTime }}
          </span>
        </div>
        <img
          v-if="profilePic() !== null"
          :src="profilePic()"
          class="user-photo ml-5 mr-3 mr-md-5"
        />
        <!-- default user photo -->
        <img
          v-else
          src="@/assets/app-header/placeholder-user-image.png"
          class="user-photo ml-5 mr-3 mr-md-5"
        />
        <template>
          <div class="text-center">
            <v-menu v-model="userMenu" :close-on-content-click="false" offset-x>
              <template v-slot:activator="{ on, attrs }">
                <img
                  src="@/assets/app-header/menu-arrow.svg"
                  class="dropdown"
                  id="app_header_user_dropdown"
                  v-bind="attrs"
                  v-on="on"
                  alt="user-menu"
                  @click="selectAdminMenuItem(null)"
                />
              </template>

              <v-card>
                <v-list
                  v-for="(item, index) in userMenuItems"
                  :key="'menuItemKey' + index"
                  class="cursor-pointer pt-0 pb-0"
                >
                  <div
                    @click="
                      item.action();
                      selectAdminMenuItem(item);
                    "
                    :id="'app_header_user_dropdown_' + item.name"
                    :class="item.selected === true ? 'selected-admin' : ''"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-1 font-weight-regular ml-0"
                        >
                          <v-icon small class="mr-1">
                            {{ item.leftIcon }}
                          </v-icon>
                          {{ $t(item.title) }}

                          <v-icon color="#2b518c">{{ item.rightIcon }}</v-icon>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider v-if="index + 1 !== userMenuItems.length" />
                  </div>
                </v-list>
              </v-card>
            </v-menu>

            <v-menu
              v-model="administrationSubMenu"
              :close-on-content-click="true"
            >
              <template v-slot:activator="{ on2, attrs2 }">
                <div v-bind="attrs2" v-on="on2" class="administrationMenu" />
              </template>
              <v-list class="pt-0 pb-0">
                <div
                  class="cursor-pointer"
                  v-for="(item, index) in administrationMenuItems"
                  :key="'subMenuitemsIndexKey' + index"
                  :id="'app_header_administration_menu_' + item.name"
                  @click="
                    goToRouteByName(item);
                    selectAdminMenuItem(null);
                  "
                >
                  <v-list-item>
                    <v-list-item-title
                      class="text-body-1 font-weight-regular ml-0"
                    >
                      {{ $t("administration." + item.title) }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider
                    v-if="index + 1 !== administrationMenuItems.length"
                  />
                </div>
              </v-list>
            </v-menu>
          </div>
        </template>
      </div>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { MnemonicService, KeycloakService } from "@bbcsinc/common-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: [
    "routes",
    // [
    //   {name:"NameOfRoute",route:"/route",requireLogin:true,clickHandler:function},
    //   {name:"Home",route:"/Home",requireLogin:true,clickHandler:routeHome},
    // ]
    "routeClickHandler",
    //Calls the function with the route
    "mainIcon",
    // '@/assets/Wheels-Icon-Home.svg'
    "mainIconRoute",
    "applicationText",
    // '@/assets/Wheels-Icon-Home.svg'
    "currentPathText",
    //String ex. Home / Donor Queue
    "versionText",
    //String
    "logoutHandler",
    "optionsHandler",
  ],
  name: "AppHeader",
  data() {
    const productChildren = [
      {
        title: "whlmain.appheader.productNavMenu.productHub",
        route: "Product Hub",
        id: "productHub",
      },
      {
        title: "whlmain.appheader.productNavMenu.donorTesting",
        route: "Unit Testing Search",
        id: "donorTesting",
      },
      {
        title: "whlmain.appheader.productNavMenu.pooling",
        route: "Pooling",
        id: "pooling",
      },
      {
        title: "whlmain.appheader.productNavMenu.productImport",
        route: "Product Import",
        id: "productImport",
      },
      {
        title: "whlmain.appheader.productNavMenu.lotRelease",
        route: "Lot Release",
        id: "lotRelease",
      },
      {
        title: "whlmain.appheader.productNavMenu.productModify",
        route: "Product Modification",
        id: "productModification",
      },
      {
        title: "whlmain.appheader.productNavMenu.labeling",
        route: "Final Labeling",
        id: "labeling",
      },
    ];

    const reportChildren = [
      {
        title: "whlmain.appheader.reports-dropdown.scorecards",
        route: "Scorecards",
        id: "scorecards",
      },
      {
        title: "whlmain.appheader.reports-dropdown.searchProducts",
        route: "Product Search Screen",
        id: "searchProducts",
      },
      {
        title: "whlmain.appheader.reports-dropdown.searchDonors",
        route: "Donor Search Screen",
        id: "searchDonors",
      },
      {
        title: "whlmain.appheader.reports-dropdown.searchAntibodiesAndAntigens",
        route: "Antibody-Antigen Donor Search Screen",
        id: "searchAntibodiesAndAntigens",
      },
      {
        title: "whlmain.appheader.reports-dropdown.saveSearches",
        route: "Saved Search",
        id: "saveSearches",
      },
    ];
    return {
      locationName: "",
      currentUser: "",
      currentTime: "",
      sourceOptions: [],
      drawer: null,

      userMenu: false,
      administrationSubMenu: false,
      productNavMenu: false,
      reportDropDown: false,
      showChildMenu: false,

      reportChildren: reportChildren,
      productChildren: productChildren,

      userMenuItems: [
        {
          title: "administration.administration",
          name: "Administration",
          leftIcon: "$optionsIcon",
          rightIcon: "mdi-menu-right",
          action: this.showAdministrationMenu,
          selected: false,
        },
        {
          title: "whlmain.appheader.leftmenu.logout",
          name: "Logout",
          leftIcon: "$logoutIcon",
          rightIcon: "",
          action: this.handleLogOut,
          selected: false,
        },
      ],
      administrationMenuItems: [
        {
          title: "collections.collections",
          name: "Collections",
          route: "Administration Collections",
        },
        {
          title: "procedures.procedures",
          name: "Procedures",
          route: "Administration Procedures",
        },
        {
          title: "products.products",
          name: "Products",
          route: "Administration Products",
        },
        {
          title: "testing.testing",
          name: "Testing",
          route: "Administration Testing",
        },
        {
          title: "sales.sales",
          name: "Sales",
          route: "Administration Sales",
        },
        {
          title: "userManagement.userManagement",
          name: "User Management",
          route: "Administration User Management",
        },
        {
          title: "system.system",
          name: "System",
          route: "Administration System",
        },
        {
          title: "configurationManagement.configurationManagement",
          name: "Configuration Management",
          route: "Administration Configuration Management",
        },
      ],

      mainNavigationItems: [
        {
          title: "donors",
          route: "Donor Hub",
          toggle: false,
          child: [],
        },
        {
          title: "products",
          route: "",
          toggle: false,
          child: [],
        },
        {
          title: "patients",
          route: "Patient Hub",
          toggle: false,
          child: [],
        },
        {
          title: "quality",
          route: "",
          toggle: false,
          child: [],
        },
        {
          title: "inventory",
          route: "Inventory",
          toggle: false,
          child: [],
        },
        {
          title: "billing",
          route: "",
          toggle: false,
          child: [],
        },
        {
          title: "reports",
          route: "",
          toggle: false,
          child: reportChildren,
        },
      ],
    };
  },
  created() {
    this.setCurrentTime();
    setInterval(this.setCurrentTime, 1000);
  },
  methods: {
    ...mapGetters("DonorManagement", ["getCollectionLocation"]),
    ...mapGetters("productStore", ["getLaboratoryLocation"]),
    ...mapGetters("auth", ["fullName", "profilePic", "username"]),
    ...mapActions("auth", ["loadCurrentUser"]),

    goToRouteByName(item) {
      if (item.route === "Product Hub") {
        this.routeToProductHub();
      } else if (item.route === "Inventory") {
        this.navToInventory();
      }
      {
        this.$routerWrapper.push({ name: item.route });
      }
    },

    selectAdminMenuItem(item) {
      for (let i = 0; i < this.userMenuItems.length; i++) {
        this.userMenuItems[i].selected = false;
      }
      if (item !== null) {
        item.selected = true;
      }
    },

    handleOptions() {
      this.$routerWrapper.push("/main/wheels-options");
    },

    handleAbout() {
      this.$routerWrapper.push("/main/about");
    },

    itemNavigation(item) {
      this.$routerWrapper.push({ name: item });
    },

    sourceDisplayValue() {
      let source = this.getCollectionLocation();
      for (let i = 0; i < this.sourceOptions.length; i++) {
        if (this.sourceOptions[i].code == source) {
          return (
            this.sourceOptions[i].code +
            " - " +
            this.sourceOptions[i].displayValue
          );
        }
      }
      return "";
    },

    setCurrentTime() {
      this.$set(this, "currentTime", moment().format("MMMM D, YYYY hh:mm A"));
    },
    navToDonorHub() {
      this.$routerWrapper.push({ name: "Donor Hub" });
    },
    navToInventory() {
      this.$routerWrapper.push("/inventory");
    },
    navToPatientHub() {
      this.$routerWrapper.push({ name: "Patient Hub" });
    },
    navToBillingHub() {
      this.$routerWrapper.push({ name: "Billing Hub" });
    },
    navToQualityHub() {
      this.$routerWrapper.push({ name: "Quality" });
    },
    routeToProductHub() {
      this.$routerWrapper.push({
        name: "Product Hub",
        params: {
          location: this.getLaboratoryLocation(),
        },
      });
    },
    routeToInventoryHub() {
      this.$routerWrapper.push({
        name: "Inventory",
        params: {
          location: this.getLaboratoryLocation(),
        },
      });
    },
    handleLogOut() {
      KeycloakService.logout()
        .then((resp) => {
          /* eslint-disable no-console */
          console.log("resp: ", resp);
          /* eslint-enable no-console */

          sessionStorage.removeItem("refresh_token");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("tenant");
          sessionStorage.removeItem("user");
          sessionStorage.removeItem("realm");
          sessionStorage.removeItem("donorDriveNo");
          MnemonicService.clearStoredMnemonics();
          window.location.href = window.location.origin;
        })
        .catch((e) => {
          /* eslint-disable no-console */
          console.log("error on logout: ", e);
          /* eslint-enable no-console */
        });
    },

    showAdministrationMenu() {
      this.administrationSubMenu = true;
    },

    showUserMenu() {
      this.userMenu = true;
    },

    showReportDropDown() {
      this.reportDropDown = !this.reportDropDown;
    },
    doNothing() {},
  },
  computed: {
    isLoggedIn() {
      return this.$user !== undefined;
    },
    getEnvironment() {
      if (this.isLoggedIn) {
        return this.$user.tenant + " / " + this.$realm;
      }
      return "";
    },
  },

  beforeMount() {
    MnemonicService.get(2).then((response) => {
      this.sourceOptions = response.data;
    });

    this.loadCurrentUser();
  },
};
</script>

<style scoped>
.app-bar {
  height: 90px;
  box-shadow: 0 6px 20px 0 rgba(55, 39, 58, 0.06);
  background-image: linear-gradient(to left, #666, #404040);
  flex-wrap: nowrap;
}

img.left-menu-logo {
  width: 190px;
  height: 40px;
  padding-right: 60px;
}

img.navbar-logo {
  width: 136px;
  height: 51px;
}

img.left-menu-icons {
  width: 24px;
  height: 25px;
}

img.side-menu {
  width: 38px;
  height: 38px;
}

img.dropdown {
  border-radius: 1px;
  cursor: pointer;
}

.left-menu-logout-row {
  position: absolute;
  bottom: 10px;
  width: 100%;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.56;
  letter-spacing: 0.72px;
  text-align: left;
  color: #2c3e50;
}

.nav-button:hover {
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.nav-button:active {
  color: rgba(255, 255, 255, 0.3);
}

.nav-button {
  margin-right: 70px;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  color: #fff;
}

@media only screen and (min-width: 1440px) {
  .nav-button {
    width: 75px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1340px) {
  .nav-button {
    margin: 30px 60px 0 0;
  }
}

.pointer {
  cursor: pointer;
}

.left-menu-text {
  width: 76px;
  height: 25px;
  margin: 0 211px 22px 0;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.56;
  letter-spacing: 0.72px;
  text-align: left;
  color: #2c3e50;
}

.time-and-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.username-text {
  height: 20px;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-family: Poppins;

  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.84px;
  text-align: right;

  font-size: 14px;
}

.time-text {
  height: 20px;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-family: Poppins;

  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.84px;
  text-align: right;

  font-size: 14px;
}

@media screen and (max-width: 1024px) {
  .time-text,
  .username-text {
    height: 12px;
    font-size: 10px;
  }

  .time-and-name {
    right: 84px;
    top: 16px;
  }
}

.user-photo {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: solid 1px #fff;
  object-fit: contain;
}

.wheels-icon-holder {
  padding-left: 5px;
  display: inline-flex;
  height: 90px;
  max-width: 282px;
}

.v-menu__content {
  border-radius: 30px !important;
}

.administrationMenu {
  position: absolute;
  left: calc(100vw - 430px);
  top: 40px;
}

.selected-admin {
  background-image: linear-gradient(to right, #fff, #dfe6e8);
}

.icon-row {
  background-color: #eef2f5;
}

.main-navigation-left {
  max-height: calc(100% - 180px);
  overflow-y: auto;
  overflow-x: hidden;
}

.normal {
  transition: all 0.2s;
}
.rotate {
  transform: rotate(90deg);
}

@media only screen and (max-width: 1440px) {
  img.navbar-logo {
    width: 100px;
    height: 40px;
  }
  .text-body-1 {
    font-size: 10px !important;
  }
  .nav-button {
    margin-right: 40px;
  }

  .time-text {
    font-size: 10px;
  }
}

@media only screen and (max-width: 910px) and (min-width: 798px) {
  .time-and-name {
    display: none;
  }
}

@media only screen and (max-width: 412px) {
  .time-and-name {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .app-bar {
    height: 56px;
  }
  img.side-menu {
    width: 28px;
    height: 28px;
  }
  .v-application .wheels-icon-holder .ml-8 {
    margin-left: 23px !important;
    margin-top: 0 !important;
  }
  .nav-button {
    margin-right: 30px;
  }

  img.navbar-logo {
    width: 85px;
    height: 33px;
  }

  .v-application .pt-9 {
    padding-top: 36px !important;
  }
  .v-application .pt-9.d-nav {
    padding-top: 20px !important;
  }

  .user-photo {
    right: 42px;
    top: 16px;
    width: 30px;
    height: 30px;
  }
  img.dropdown {
    right: 20px;
    top: 28px;
  }
}

@media only screen and (max-width: 940px) {
  .nav-button {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 850px) {
  .d-nav {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .app-bar {
    height: 76px;
  }
  img.navbar-logo {
    width: 102px;
    height: 39px;
  }
  .d-nav {
    display: none;
  }
}

@media only screen and (max-width: 412px) {
  #appheader-current-time {
    display: none !important;
  }
}
</style>
