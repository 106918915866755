const defaults = {
  soundex: "",
  lastName: "",
  firstName: "",
  middleName: "",
  nameSuffix: "",
  gender: "",
  birthdate: "", //2020-02-28T16:24:42.844+0000
  ssn: "",
  uuid: "",
  donorId: "",
  searchType: 0,
  phone: "",
  other: "",
  startPos: 0,
  recSize: 100,
  maxSize: 0,
};

const defaultDonor = {
  product: "",
  donationType: "",
  unit: "",
  appearanceUuid: "",
  appearanceDate: "",
  donor: {
    id: 0,
    uuid: "",
    name_last: "",
    name_first: "",
    name_middle: "",
    name_suffix: "",
    ssn: 0,
    gender: "43",
    race: "",
    birthdate: "1970-01-01",
    print_card: "",
    confidential_code: "",
    soundex: "",
    recruit_interval: "",
    group_category: "",
    employer_name: "",
    last_group: "",
    donor_code: "",
    last_product: "",
    cmv_results: "",
    blood_type: "",
    next_recruit: null,
    deferred_until: null,
    created_by: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    zipExtension: "",
    geoCode: "",
    image_name: "",
    contactList: [],
    donorAwards: [
      {
        awardType: 1,
        awardLevel: "",
        awardedAt: null,
      },
      {
        awardType: 2,
        awardLevel: "",
        awardedAt: null,
      },
    ],
  },
};

export const whldonor = {
  namespaced: true,
  state: {
    donorCriteria: defaults,
    webDonation: JSON.parse(JSON.stringify(defaultDonor)),
    donorNumbers: {
      id: 0,
    },
    backToDemo: {
      backToDemo: "false",
    },
    lastDrive: {
      drive: "",
      group: "",
    },
    origDonorInfo: {},
  },
  actions: {},
  mutations: {
    initDonorData(state, donorData) {
      state.origDonorInfo = { ...state.origDonorInfo, ...donorData };
    },
    updateDonorData(state, donorData) {
      // state.origDonorInfo = {...state.origDonorInfo,  deepcopy(data) };
      state.origDonorInfo = { ...donorData };
    },

    updateDonorCriteria(state, data) {
      state.donorCriteria = { ...state.donorCriteria, ...data };
      // console.log("store update");
      // console.log(state.donorCriteria);
    },
    resetDonorCriteria(state) {
      state.donorCriteria = defaults;
    },

    updateDonorNumbers(state, data) {
      state.donorNumbers = { ...state.donorNumbers, ...data };
    },

    updateDonor(state, data) {
      state.webDonation.donor = { ...state.webDonation.donor, ...data };
    },

    resetDonor(state) {
      state.webDonation = JSON.parse(JSON.stringify(defaultDonor));
    },

    updateDonation(state, data) {
      state.webDonation = { ...state.webDonation, ...data };
    },

    updateBackToDemo(state, data) {
      state.backToDemo = { ...state.backToDemo, ...data };
    },

    updateLastDrive(state, data) {
      state.lastDrive = { ...state.lastDrive, ...data };
    },
  },
};
