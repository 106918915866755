export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    $moneyFormat(number) {
      if (isNaN(number)) {
        number = "";
      } else {
        number = Number(number).toLocaleString("en-US", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        });
      }
      return `$ ${number}`;
    },
    $money(number) {
      if (isNaN(number)) {
        number = "";
      } else {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        number = formatter.format(number);
      }
      return number;
    },
  },
};
