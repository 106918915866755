const defaults = {
  orderNumber: 0,
  status: "New",
  assignedTo: "",
  customerCode: "",
  customerLocationCode: "",
  laboratoryCode: "",
  orderMethod: "",
  requestedDeliveryDate: "",
  requestedDeliveryTime: "",
  requiredAt: "",
  placedBy: "",
  placedAt: "",
  receivedBy: "",
  receivedAt: "",
  orderNote: "",
  noteDate: new Date(),
  orderDetailDtoSet: [],
  orderCommentDtoSet: [],
};

export const whlInventory = {
  namespaced: true,
  state: {
    orderFormData: defaults,
  },
  mutations: {
    setOrderFormData(state, data) {
      state.orderFormData = { ...data };
    },
  },
  getters: {
    getOrderFormData: (state) => {
      return { ...state.orderFormData };
    },
  },
};
