// import {ConfigurationService} from "@bbcsinc/common-vue";

export const productStore = {
  namespaced: true,
  state: {
    storeLocation: null,
  },

  getters: {
    getLaboratoryLocation: (state) => {
      return state.storeLocation;
    },
  },
  mutations: {
    setLaboratoryLocation(state, location) {
      state.storeLocation = location;
    },
  },
};
