<template>
  <common-dialog :value="value">
    <template slot="content">
      <p>{{ this.BBCS0019 }}</p>
    </template>
    <template slot="footer">
      <div class="w-full d-flex justify-space-between">
        <common-button
          variant="primary"
          size="lg"
          @click="back"
          aria-label="Close Dialog"
        >
          {{ $t("whldonscrn.detailprocessing.btnYes") }}
        </common-button>

        <common-button
          variant="secondary"
          size="lg"
          @click="close"
          aria-label="Close Dialog"
        >
          {{ $t("whldonscrn.detailprocessing.btnNo") }}
        </common-button>
      </div>
    </template>
  </common-dialog>
</template>

<script>
export default {
  name: "modal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      BBCS0019: this.$t("messages.0019"),
    };
  },
  methods: {
    back() {
      this.$emit("close");
      this.$emit("clicked", "YES");
    },
    close() {
      this.$emit("close");
      this.$emit("clicked", "NO");
    },
  },
};
</script>
