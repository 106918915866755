//convenience router Wrapper that sets allowNav = true.

import router from "@/router";
import store from "@/store";

const funcs = {
  push(location, onComplete, onAbort) {
    store.commit("leftnav/appendPath", { location: location, router: router });
    store.commit("whlsecurity/setAllowNav", true);
    return router.push(location, onComplete, onAbort);
  },
  replace(location, onComplete, onAbort) {
    store.commit("leftnav/replacePath", { location: location, router: router });
    store.commit("whlsecurity/setAllowNav", true);
    return router.replace(location, onComplete, onAbort);
  },
  go(n) {
    store.commit("whlsecurity/setAllowNav", true);
    if (n < -1) {
      // updates the breadcrumb
      store.commit("leftnav/backPath", Math.abs(n));
    }
    return router.go(n);
  },
};

export default funcs;
