export const whlqpass = {
  namespaced: true,
  state: {
    qpass: {
      qpass: "",
      questionsRemaining: 0,
    },
  },
  actions: {},
  mutations: {
    updateQpass(state, data) {
      state.qpass = { ...state.qpass, ...data };
    },
    resetQpass(state) {
      state.qpass = {
        qpass: "",
        questionsRemaining: 0,
      };
    },
  },
};
