import axios from "axios";
import router from "@/router";
import store from "@/store";
import AuthService from "@/modules/main/services/auth.services";
import axiosRetry from "axios-retry";

let isRefreshing = false;
let failQueue = [];

const processFailQueue = (error, token = null) => {
  failQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failQueue = [];
};

export default function setup() {
  axios.defaults.baseURL = import.meta.env.VITE_APP_API_BASEURL;
  axiosRetry(axios, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: axiosRetry.isRetryableError,
  });
  axios.interceptors.request.use(
    function (config) {
      axios.defaults.baseURL = import.meta.env.VITE_APP_API_BASEURL;
      const token = AuthService.getToken();
      //add access token to every request
      if (!config.headers["refresh_token"]) {
        config.headers.Authorization = token ? `Bearer ${token}` : "";
      }
      config.headers["x-timezone"] =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
      config.headers["x-tenantid"] = sessionStorage.getItem("tenant");
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      // Return a successful back to the calling service
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      //TODO Figure out this for keyCLoak; after wheels split?
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry &&
        error.config.url != "/collections/api/auth/signin" &&
        error.config.url != "/collections/api/unit/refresh"
      ) {
        //   Return anything not related to authentication
        //  Logout if renew fail
        if (error.config.url == "/collections/api/auth/renew") {
          // AuthService.clearTokens();
          store.dispatch("auth/logout");
          store.commit("whlsecurity/setAllowNav", true);
          router.push({ name: "logged-out" });
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }

        if (isRefreshing && error.response && error.response.status === 401) {
          AuthService.clearTokens();
          location.reload();
        }

        originalRequest._retry = true;
        isRefreshing = true;

        // Try request again with new token
        return new Promise(function (resolve, reject) {
          AuthService.getNewToken()
            .then((token) => {
              // window.sessionStorage.setItem('token', data.token);
              // window.sessionStorage.setItem('refreshToken', data.refreshToken);
              // axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
              originalRequest.headers["Authorization"] = "Bearer " + token;
              processFailQueue(null, token);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processFailQueue(err, null);
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }

      return Promise.reject(error);
    }
  );
}
