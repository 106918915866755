<template>
  <v-row class="main-pane mb-10">
    <v-col v-if="leftPaneIncluded()" class="pa-0 ma-0 left-pane-holder">
      <slot name="left-pane" />

      <v-footer
        class="left-pane-holder no-margin-left"
        fixed
        v-if="slotsContainsKeyButtons('leftPane')"
      >
        <slot name="leftPaneLeftButtons"></slot>
        <slot name="leftPaneCenterButtons"></slot>
        <slot name="leftPaneRightButtons"></slot>
      </v-footer>
    </v-col>

    <v-col
      :class="rightPaneIncluded() ? 'footer-right pa-0 ma-0' : 'pa-0 ma-0'"
    >
      <div
        v-if="showLocation !== undefined && showLocation !== null"
        class="custom-location"
      >
        <Location :selectable="locationSelectable" />
      </div>
      <div
        :class="{
          'mt-15': showLocation !== undefined && showLocation !== null,
        }"
      >
        <slot />
      </div>
      <v-footer :class="footerMargin" padless fixed>
        <!--   fixed -->

        <!-- <modals-container v-if="!isModal" v-on:add-error="addError($event)" /> -->
        <ErrorBar v-bind:errors="errors" />
        <ButtonBar
          :showTopButtons="showTopButtons"
          v-if="noButtons == undefined && slotsContainsKeyButtons('Buttons')"
        >
          <template v-slot:topSide>
            <slot name="topButtons"></slot>
          </template>
          <template v-slot:leftSide>
            <slot name="leftButtons"></slot>
          </template>
          <template v-slot:centerSide>
            <slot name="centerButtons"></slot>
          </template>
          <template v-slot:rightSide>
            <slot name="rightButtons"></slot>
          </template>
        </ButtonBar>
      </v-footer>
    </v-col>
    <v-col
      v-if="rightPaneIncluded()"
      class="pa-0 ma-0 right-pane-holder right-pane"
    >
      <slot name="right-pane" />

      <v-footer
        class="left-pane-holder"
        fixed
        v-if="slotsContainsKeyButtons('rightPane')"
      >
        <slot name="rightPaneLeftButtons"></slot>
        <slot name="rightPaneCenterButtons"></slot>
        <slot name="rightPaneRightButtons"></slot>
      </v-footer>
    </v-col>
  </v-row>
</template>

<script>
import ButtonBar from "./ButtonBar.vue";
import ErrorBar from "./ErrorBar.vue";
import Location from "./Location.vue";
export default {
  props: [
    "title",
    "showTopButtons",
    "errors",
    "isModal",
    "leftHandPane",
    "noButtons",
    "displayBreadCrumb",
    "showLocation",
    "loc",
    "locationSelectable",
  ],
  name: "ContentPane",
  components: {
    ButtonBar,
    ErrorBar,
    Location,
  },
  data() {
    return {};
  },
  created() {
    this.fetchData();
  },
  methods: {
    updateLocation(location) {
      this.$emit("update-location", location);
    },
    isBreadCrumbDisplayed() {
      if (this.displayBreadCrumb == false) {
        return false;
      } else {
        return true;
      }
    },
    addError: function ($err) {
      this.errors.push($err);
    },
    leftPaneIncluded() {
      return Object.keys(this.$slots).includes("left-pane");
    },
    rightPaneIncluded() {
      return Object.keys(this.$slots).includes("right-pane");
    },
    slotsContainsKeyButtons(key) {
      let keys = Object.keys(this.$slots);
      for (let i = 0; i < keys.length; i++) {
        if (keys[i].includes(key)) {
          return true;
        }
      }
      return false;
    },
    fetchData() {},
  },
  computed: {
    footerMargin() {
      let result = "no-margin-left";
      if (this.leftPaneIncluded()) {
        result += " footer-left";
      }
      // if (this.rightPaneIncluded()) {
      //    result += " footer-right"
      // }
      return result;
    },
  },
};
</script>

<style scoped>
.left-pane-holder {
  max-width: 396px;
  min-width: 396px;
  min-height: 120%;
  background-color: #eff5f9;
}
.right-pane-holder {
  max-width: 250px;
  min-width: 250px;
}
.right-pane {
  float: end;
  position: fixed;
  right: 0;
  height: calc(100vh - 128px);
}
.no-margin-left {
  margin-left: 0px;
  margin-bottom: 40px;
}
.footer-left {
  margin-left: 396px;
}
.footer-right {
  margin-right: 250px !important;
}
.main-pane {
  margin: 0;
  padding: 0;
  height: calc(100vh - 128px);
  background-color: #f8f8f8 !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.custom-location {
  position: absolute;
  margin-top: 36px;
}
</style>
