import DonorManagementService from "@/modules/donor/management/services/DonorManagement.service.js";
import router from "@/router";
// import moment from 'moment';
import { DateTime } from "luxon";

const storedFilters = JSON.parse(sessionStorage.getItem("storedFilters"));
const defaults = [
  { id: "appt", name: "APPOINTMENTS", enabled: true },
  { id: "reg", name: "REGISTRATION CMP", enabled: true },
  { id: "qip", name: "QUESTIONS IP", enabled: true },
  { id: "qcmp", name: "QUESTIONS CMP", enabled: true },
  { id: "sip", name: "SCREENING IP", enabled: true },
  { id: "scmp", name: "SCREENING CMP", enabled: true },
  { id: "pip", name: "PHLEBOTOMY IP", enabled: true },
  { id: "pcmp", name: "PHLEBOTOMY CMP", enabled: true },
];
const initialState = storedFilters ? storedFilters : defaults.slice();

export const DonorManagement = {
  namespaced: true,
  state: {
    source: "",
    userFilters: initialState,
    counts: {
      appt: 0,
      reg: 0,
      qip: 0,
      qcmp: 0,
      sip: 0,
      scmp: 0,
      pip: 0,
      pcmp: 0,
    },
    mutextMap: new Map(),
    mutexRefreshFail: false,
  },
  getters: {
    mutexRefreshFail(state) {
      return state.mutexRefreshFail;
    },
    getCollectionLocation: (state) => {
      return state.source;
    },
  },
  mutations: {
    editFilter(state, { filter, enabled = filter.enabled }) {
      const index = state.userFilters.indexOf(filter);
      state.userFilters.splice(index, 1, {
        ...filter,
        enabled,
      });

      let key =
        sessionStorage.getItem("tenantid") +
        sessionStorage.getItem("activeuser");
      sessionStorage.setItem(key, JSON.stringify(state.userFilters));

      let map = new Map();
      map.set(key, JSON.stringify(state.userFilters));
      sessionStorage.setItem("userfilters", map);
    },
    updateCounts(state, counts) {
      state.counts = { ...state.counts, ...counts };
    },
    setFilter(state, filter) {
      state.userFilters = filter;
    },
    setMutextRefreshIntervalID(state, mutex) {
      state.mutextMap.set(mutex.personUuid, mutex.id);
    },
    setMutexRefreshFail(state, val) {
      state.mutexRefreshFail = val;
    },
    clearMutextMap(state) {
      state.mutextMap.clear();
    },
    setCollectionLocation(state, data) {
      if (sessionStorage.getItem("donorDriveNo") && state.source !== data) {
        sessionStorage.removeItem("donorDriveNo");
      }
      state.source = data;
    },
  },
  actions: {
    toggleFilter({ commit }, filter) {
      commit("editFilter", { filter, enabled: !filter.enabled });
    },
    acquirePersonMutex(context, personUuid) {
      context.commit("setMutexRefreshFail", false);
      return new Promise((resolve, reject) => {
        // return DonorManagementService.acquirePersonMutex(unit);
        DonorManagementService.acquirePersonMutex(personUuid)
          .then((result) => {
            if (!context.state.mutextMap.has(personUuid)) {
              context.dispatch("startMutexRefresh", result.data);
            }
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    startMutexRefresh(context, personMutex) {
      context.commit("setMutexRefreshFail", false);
      let diffMs = DateTime.fromISO(personMutex.expires).diffNow(
        "milliseconds"
      );
      diffMs = 90000;
      let refreshPeriod = diffMs * 0.8;
      const intervalID = setInterval(() => {
        DonorManagementService.refreshPersonMutex(personMutex.personUuid).catch(
          (error) => {
            context.dispatch("stopMutexRefresh", personMutex.personUuid);
            //if 401 logout
            if (error.response && error.response.status === 401) {
              context.dispatch("auth/logout", null, { root: true });
              context.commit("whlsecurity/setAllowNav", true, { root: true });
              router.push({ name: "logged-out" });
            } else {
              context.commit("setMutexRefreshFail", true);
            }
          }
        );
      }, refreshPeriod);

      context.commit("setMutextRefreshIntervalID", {
        id: intervalID,
        personUuid: personMutex.personUuid,
      });
    },
    stopMutexRefresh({ state }, personUuid) {
      let id = state.mutextMap.get(personUuid);
      clearInterval(id);
      state.mutextMap.delete(personUuid);
    },
    releasePersonMutex(context, personUuid) {
      context.dispatch("stopMutexRefresh", personUuid);
      return DonorManagementService.releasePersonMutex(personUuid);
    },
    releaseAllMutexRefresh({ dispatch, state, commit }) {
      state.mutextMap.forEach(function (value, key) {
        dispatch("releasePersonMutex", key);
      });
      commit("clearMutextMap");
      commit("setMutexRefreshFail", false);
    },
  },
};
