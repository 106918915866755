<template>
  <v-app>
    <AppHeader
      id="abo-cloud-header"
      :routes="this.setRoutes"
      :routeClickHandler="this.routeHandler"
      :application-text="'Cloud'"
      :versionText="getVersion"
      :mainIcon="mainIcon"
      :logoutHandler="handleLogOut"
      :mainIconRoute="mainIconRoute"
    />
    <slot />
    <AppFooter id="abo-cloud-footer" />
  </v-app>
</template>

<script>
import AppFooter from "@/modules/main/layouts/components/AppFooter";
import { MnemonicService } from "@bbcsinc/common-vue";
import aboWheelsIcon from "@/assets/legacy-icons/abo-wheels-icon.svg";
import { mapGetters, mapMutations } from "vuex";
import store from "@/store";

const resourcePath = "layouts.content-pane.";

export default {
  name: "WheelsLayout",
  components: {
    AppFooter,
  },
  data() {
    return {
      rp: resourcePath,
      aboCloudRoutes: [
        {
          name: this.$t("whlmain.appheader.btnDonors"),
          route: "/whldonor/donor-hub",
          requireLogin: true,
        },
        {
          name: this.$t("whlmain.appheader.products"),
          route: "/product-hub",
          requireLogin: true,
        },
        {
          name: this.$t("whlmain.appheader.patients"),
          requireLogin: true,
        },
        {
          name: this.$t("whlmain.appheader.quality"),

          requireLogin: true,
        },
        {
          name: this.$t("whlmain.appheader.inventory"),
          route: "/inventory",
          requireLogin: true,
        },
        {
          name: this.$t("whlmain.appheader.billing"),

          requireLogin: true,
        },
        {
          name: this.$t("whlmain.appheader.reports"),

          requireLogin: true,
        },
      ],
      mainIcon: aboWheelsIcon,
      locationName: " ",
      mainIconRoute: "/cloud-dashboard",
      currentPath: "/",
      version: this.$version,
    };
  },

  methods: {
    ...mapGetters("leftnav", ["getNav"]),
    ...mapMutations("leftnav", ["appendPath", "backPath"]),
    token() {
      return sessionStorage.getItem("user");
    },
    handleLogOut() {
      this.$store.dispatch("auth/logout").then(() => {
        MnemonicService.clearStoredMnemonics();
        store.commit("leftnav/clearPath");
        // this.$routerWrapper.push('/login');
      });
    },
    routeHandler(route) {
      let pop_length = this.getNav().length;
      this.backPath(pop_length);
      this.$routerWrapper.push(route);
    },
    launchOptions() {
      this.$routerWrapper.push("/main/wheels-options");
    },
    launchAbout() {
      this.$routerWrapper.push("/main/about");
    },
  },
  computed: {
    getVersion() {
      return "V " + this.$version.major + "." + this.$version.minor;
    },
    setRoutes() {
      return this.aboCloudRoutes;
    },
  },
};
</script>

<style scoped></style>
