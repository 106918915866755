import Vue from "vue";
import VueI18n from "vue-i18n";

import ISO6391 from "iso-639-1";
import { locale_en, locale_es } from "@bbcsinc/common-vue";

export const supported = getSupportedKeysDetail();
export const supportedIds = getSupportedKeys();
export const preferredLang = getPreferredId();

export function getGroomedList(val) {
  // prep basic code
  if (val.length >= 2) {
    val = val.substr(0, 2);
    //validate code
    if (ISO6391.validate(val)) {
      // check of if we need to do work
      if (supported[0].code != val) {
        let inx = findWithAttr(supported, "code", val);
        if (inx > 0) {
          return moveToTop(supported, inx);
        }
      }
    }
  }
  // invalid length , default
  return supported;
}

function findWithAttr(array, attr, value) {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

function moveToTop(arr, old_index) {
  if (0 >= arr.length) {
    let k = 0 - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(0, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

function getPreferredId() {
  let code = window.navigator.languages[0];
  if (window.navigator.languages[0].length > 2) {
    code = code.substr(0, 2);
  }
  if (ISO6391.validate(code)) {
    return code;
  } else {
    return "en";
  }
}

function getLocales() {
  const locales = import.meta.glob("./locales/*.json", { eager: true });

  const locales_list = Object.keys(locales).map((locale) => {
    const locale_key = locale.replace("./locales/", "").replace(".json", "");

    return {
      key: locale_key,
      val: locales["./locales/" + locale_key + ".json"].default,
    };
  });

  return locales_list.reduce((locales_map, locale) => {
    locales_map[locale.key] = locale.val;
    return locales_map;
  }, {});
}

function getSupportedKeysDetail() {
  let ids = getSupportedKeys();
  let results = ISO6391.getLanguages(ids);
  return results;
}

function getSupportedKeys() {
  let locales = getLocales();

  return Object.keys(locales);
}

function loadLocaleMessages() {
  const app_locales = getLocales();

  const locales = {
    en: {
      ...app_locales.en,
      ...locale_en,
    },
    es: {
      ...app_locales.es,
      ...locale_es,
    },
  };

  return locales;
}

const messages = loadLocaleMessages();

Vue.use(VueI18n);

export default new VueI18n({
  locale: window.navigator.languages[0],
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: messages,
  silentTranslationWarn: true,
});
