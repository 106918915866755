import axios from "axios";
//import VueJwtDecode from "vue-jwt-decode";
import store from "@/store/index";
import { ConfigurationService } from "@bbcsinc/common-vue";
import { AuthenticationService } from "@bbcsinc/common-vue";

import Vue from "vue";
import i18n from "@/i18n";

import UserService from "@/modules/administration/user-management/components/users/services/User.service";

//const RESOURCE_NAME = '/collections/api/auth';
const LOCAL_STORAGE_REFRESH_TOKEN = "refresh_token";
const LOCAL_STORAGE_TOKEN = "token";

class AuthService {
  async keyLogin(keycloak) {
    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;  //this is now set in interceptor
    axios.defaults.headers.common["Accept-Language"] = i18n.locale;

    // let token = keycloak.token;
    sessionStorage.setItem("user", JSON.stringify(keycloak));

    //   if (response.data.userPrincipal) {
    sessionStorage.setItem("environment", "TESTENV1234");
    // }
    //  this.storeToken(response.data.accessToken);
    //  this.storeRefreshToken(response.data.refreshToken);
    let userBlock = JSON.parse(sessionStorage.getItem("user"));

    if (userBlock) {
      let tenant = "default";
      //   if (userBlock["x-tenantid"]) {
      tenant = "EEEEEV";
      //  }
      let activeuser = userBlock.tokenParsed.preferred_username;
      sessionStorage.setItem("tenantid", tenant);
      sessionStorage.setItem("activeuser", activeuser);
      let defaults = [
        { id: "appt", name: "APPOINTMENTS", enabled: true },
        { id: "reg", name: "REGISTRATION CMP", enabled: true },
        { id: "qip", name: "QUESTIONS IP", enabled: true },
        { id: "qcmp", name: "QUESTIONS CMP", enabled: true },
        { id: "sip", name: "SCREENING IP", enabled: true },
        { id: "scmp", name: "SCREENING CMP", enabled: true },
        { id: "pip", name: "PHLEBOTOMY IP", enabled: true },
        { id: "pcmp", name: "PHLEBOTOMY CMP", enabled: true },
      ];
      let filterkey = tenant + activeuser;
      let filterblock = JSON.parse(sessionStorage.getItem(filterkey));

      /!* todo *!/;
      // let filtermap = JSON.parse(sessionStorage.getItem('userfilters')) ;
      // //cover case of NO filters ever
      // // or never that key
      // if(!filtermap || !filtermap[filterkey]){
      //     let map= new Map();
      //     map.set(filterkey,  defaults );
      //     console.log(JSON.stringify( map) )
      //     sessionStorage.setItem("userfilters",   JSON.stringify( map)   );
      //     store.commit('whldnrmgmt/setFilter',   defaults  );
      //     // this.$store.whldnrmgmt.mutations.setFilter(whldnrmgmt.state.userFilters, JSON.stringify(defaults) );
      // }else{
      //
      //     store.commit('whldnrmgmt/setFilter',   JSON.parse(filtermap[filterkey] ) );
      //
      //     // this.$store.whldnrmgmt.mutations.setFilter(whldnrmgmt.state.userFilters, JSON.stringify(filtermap[filterkey]) );
      // }
      if (!filterblock) {
        sessionStorage.setItem(filterkey, JSON.stringify(defaults));
      } else {
        store.commit("DonorManagement/setFilter", filterblock);
      }
    }
    await UserService.getSystemUsers();
    ConfigurationService.loadConfigurations()
      .then()
      .catch((error) => {
        /* eslint-disable no-console */
        console.log("configuration load failed");
        console.log(error);
        /* eslint-enable no-console */
        //TODO: handle failed configuration
      });
  }

  logout() {
    //TODO: Figure out keycloak logout; logout after key expire
    // this.invalidate();
    this.getCloak().logout();
    axios.defaults.headers.common["Authorization"] = "";
    sessionStorage.removeItem("user");
    sessionStorage.clear();
    this.clearTokens();
  }

  async getNewToken() {
    await AuthenticationService.getAuthToken()
      .then((auth) => {
        if (auth && auth.status === 200 && auth.data) {
          sessionStorage.setItem(LOCAL_STORAGE_TOKEN, auth.data);
          return Promise.resolve(auth.data);
        } else {
          this.logout();
        }
      })
      .catch((error) => {
        /* eslint-disable no-console */
        console.log("Failed to refresh token", error);
        /* eslint-enable no-console */
        if (error.response.status === 401) {
          AuthenticationService.clearStorage();
          location.reload();
          return Promise.reject(error);
        }
      });
  }

  //     return new Promise((resolve, reject) => {
  //         let token = this.getRefreshToken();
  //         let tenantid = sessionStorage.getItem('tenantid');
  //         let user = sessionStorage.getItem('activeuser');
  //         axios
  //             .post(RESOURCE_NAME + '/renew',
  //                 {
  //                     refreshToken: token,
  //                 username : user,
  //                 tenantID : tenantid })
  //             .then(response => {
  //                 this.storeToken(response.data.accessToken);
  //                 this.storeRefreshToken(response.data.refreshToken);
  //                 let token = response.data.accessToken;
  //                 let assembled = {...response.data, ...VueJwtDecode.decode(token)};
  //                 sessionStorage.setItem('user', JSON.stringify(assembled));
  //
  //                 resolve(response.data.accessToken);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     });
  //}

  // invalidate() {
  //     //TODO: figure out invalidate
  //     return new Promise(() => {
  //         let token = this.getRefreshToken();
  //         let tenantid = sessionStorage.getItem('tenantid')
  //         let user = sessionStorage.getItem('activeuser')
  //         axios
  //             .post(RESOURCE_NAME + '/invalidate',
  //                 {
  //                     refreshToken: token,
  //                     username : user,
  //                     tenantID : tenantid })
  //             .then(response => {
  //                 resolve(response);
  //             })
  //             .catch((error) => {
  //                 reject(error);
  //             });
  //     });
  // }

  getCloak() {
    return Vue.prototype.$keycloak;
  }

  getRefreshToken() {
    let token = sessionStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
    return token;
  }

  getToken() {
    return sessionStorage.getItem(LOCAL_STORAGE_TOKEN);
  }

  storeToken(token) {
    sessionStorage.setItem(LOCAL_STORAGE_TOKEN, token);
  }

  storeRefreshToken(refreshToken) {
    sessionStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
  }

  clearTokens() {
    sessionStorage.removeItem(LOCAL_STORAGE_TOKEN);
    sessionStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
    sessionStorage.removeItem("KEYCLOAK");
  }
}

export default new AuthService();
