import Axios from "axios";

const PersonMutexAcquire = "/collections/api/whlpersonlock/acquire";
const PersonMutexRelease = "/collections/api/whlpersonlock/release";
const PersonMutexRefresh = "/collections/api/whlpersonlock/refresh";
export default {
  acquirePersonMutex(personUuid) {
    return Axios.post(PersonMutexAcquire, {
      personUuid: personUuid,
    });
  },
  refreshPersonMutex(personUuid) {
    return Axios.post(PersonMutexRefresh, {
      personUuid: personUuid,
    });
  },
  releasePersonMutex(personUuid) {
    return Axios.post(PersonMutexRelease, {
      personUuid: personUuid,
    });
  },
};
