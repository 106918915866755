import AuthService from "@/modules/main/services/auth.services";
import UserService from "@/modules/administration/user-management/components/users/services/User.service";

const ASSIGN_OVERRIDE_MESSAGES_ROLE = "view-clients";

const initialState = {
  loggedIn: false,
  email_verified: false,
  username: "",
  company: "",
  groups: [],
  accountRoles: [],
  overrideMessageRoles: [],
  realmManagementRoles: [],
  firstName: "",
  lastName: "",
  profilePic: null,
};

// login outsourced to common-vue
// logout should be updated & used for common logout operations
export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    async logout({ commit, dispatch }) {
      try {
        await dispatch("DonorManagement/releaseAllMutexRefresh", null, {
          root: true,
        });
      } finally {
        AuthService.logout();
        commit("logout");
      }
    },
    async loadCurrentUser({ commit }) {
      // UserService does not get the expanded roles by grants - UserRoles does
      try {
        const resp = await UserService.getUsers(
          0,
          1,
          true,
          sessionStorage.getItem("activeuser")
        );

        const user = resp.data.content[0];

        commit("firstName", user.firstName);
        commit("lastName", user.lastName);
        commit("username", user.username);

        if (user.profilePicture && user.profilePicture.picture !== "") {
          commit(
            "profilePic",
            "data:" +
              user.profilePicture.type +
              ";base64," +
              user.profilePicture.picture
          );
        }
      } catch (error) {
        //eslint-disable-next-line
        console.error("User not found?" + error);
      }
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    roles(state, roles) {
      state.realmManagementRoles = [...roles];
    },
    firstName(state, firstName) {
      state.firstName = firstName;
    },
    lastName(state, lastName) {
      state.lastName = lastName;
    },
    username(state, username) {
      state.username = username;
    },
    profilePic(state, picture) {
      state.profilePic = picture;
    },
  },
  getters: {
    isLoggedIn: (state) => {
      return state.status.loggedIn;
    },
    roles: (state) => {
      return state.realmManagementRoles;
    },
    hasRole: (state) => (role) => {
      return state.realmManagementRoles.includes(role);
    },
    canAssignOverrideMessageRoles: (state, getters) => {
      return getters.hasRole(ASSIGN_OVERRIDE_MESSAGES_ROLE);
    },
    fullName: (state) => {
      return (state.firstName + " " + state.lastName).trim();
    },
    username: (state) => {
      return state.username;
    },
    profilePic: (state) => {
      return state.profilePic;
    },
  },
};
