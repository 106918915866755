export const whlreg = {
  namespaced: true,
  state: {
    currentAppointment: null,
  },

  getters: {
    getCurrentAppointment: (state) => {
      return state.currentAppointment;
    },
  },
  mutations: {
    clearCurrentAppointment: (state) => {
      state.currentAppointment = null;
    },

    setCurrentAppointment(state, appointment) {
      state.currentAppointment = appointment;
    },
  },
};
