<template>
  <common-dialog :value="true">
    <template slot="content">
      <p>{{ text }}</p>
    </template>
    <template slot="footer">
      <div v-if="dialogType != undefined && dialogType.toUpperCase() === 'OK'">
        <common-button variant="primary" size="lg" @click="ok">
          {{ $t("components.util.ok") }}
        </common-button>
      </div>
      <div v-else class="w-full d-flex justify-space-between">
        <common-button variant="secondary" size="lg" @click="close">
          {{ $t("components.util.no") }}
        </common-button>

        <common-button variant="primary" size="lg" @click="back">
          {{ $t("components.util.yes") }}
        </common-button>
      </div>
    </template>
  </common-dialog>
</template>

<script>
export default {
  name: "ProceedOrCancel",
  props: ["text", "dialogType"],

  data() {
    return {};
  },
  methods: {
    back() {
      this.$emit("close");
      this.$emit("clicked", "YES");
    },
    close() {
      this.$emit("close");
      this.$emit("clicked", "NO");
    },
    ok() {
      this.$emit("close");
      this.$emit("clicked", "OK");
    },
  },
};
</script>

<style></style>
