<template>
  <v-dialog persistent :value="true" :max-width="modalWidth">
    <v-card>
      <v-card-text class="px-7 py-9">
        <div class="text-h5 secondary--text bold mb-3">
          {{ $t(rp + "idleSessionTitle") }}
        </div>

        <div class="secondary--text mb-3">
          {{ $t(rp + "idleSessionContent") }}
        </div>

        <div
          class="mt-15 mb-5 mt-md-0 d-flex flex-column-reverse flex-sm-row justify-sm-space-between action-btns"
        >
          <common-button
            id="logOut"
            color="secondary"
            secondary
            class="logOutBtn"
            @click="idleLogOut"
          >
            {{ $t(rp + "idleSessionLogoutBtn") }} {{ time }}
            {{ $t(rp + "idleSessionLogoutInSec") }}
          </common-button>

          <common-button
            id="stayLoggedIn"
            color="primary"
            primary
            class="ml-0 ml-sm-2 ml-md-0 save-btn"
            @click="staySignIn"
          >
            {{ $t(rp + "idleSessionStayLoggedInBtn") }}
          </common-button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { MnemonicService } from "@bbcsinc/common-vue";
import { KeycloakService } from "@bbcsinc/common-vue";
import { ConfigurationService } from "@bbcsinc/common-vue";

const resourcePath = "modules.administration.idle-session.";

export default {
  emits: ["close"],
  data() {
    return {
      time: 2 * 60, //defauls time in Seconds
      rp: resourcePath,
      timerId: null,
    };
  },
  created() {
    if (ConfigurationService.getConfigByKey("IDLETIMEOUTWARNING")) {
      var warning = ConfigurationService.getConfigByKey("IDLETIMEOUTWARNING");
      if (warning > 0) {
        this.time = warning * 60;
      }
    }
    this.timerId = setInterval(() => {
      this.time -= 1;
      if (this.time < 1) {
        clearInterval(this.timerId);
        this.idleLogOut();
      }
    }, 1000);
  },
  computed: {
    modalWidth() {
      let width = "528px";
      if (this.$vuetify.breakpoint.smOnly) width = "400px";
      if (this.$vuetify.breakpoint.xsOnly) width = "350px";
      return width;
    },
  },
  methods: {
    idleLogOut() {
      KeycloakService.logout()
        .then(() => {
          sessionStorage.removeItem("refresh_token");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("tenant");
          sessionStorage.removeItem("user");
          sessionStorage.removeItem("realm");
          MnemonicService.clearStoredMnemonics();
          window.location.href = window.location.origin;
        })
        .catch();
    },
    staySignIn() {
      if (ConfigurationService.getConfigByKey("IDLETIMEOUTWARNING")) {
        var warning = ConfigurationService.getConfigByKey("IDLETIMEOUTWARNING");
        if (warning > 0) {
          this.time = warning * 60;
        }
      } else {
        this.time = 120;
      }
      clearInterval(this.timerId);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.action-btns {
  .v-btn {
    width: 222px !important;
    height: 52px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .action-btns {
    .v-btn {
      width: 160px !important;
      height: 36px !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .action-btns {
    .v-btn {
      width: 100% !important;
      height: 48px !important;
      margin: 5px 0;
    }
  }
}
</style>
