<template>
  <ContentPane :title="title" :show-location="null" id="admin-layout">
    <v-row no-gutters class="h-full align-center">
      <v-col cols="12" md="4" lg="3" class="overflow-hidden d-none d-sm-block">
        <div class="text-h4 primary--text py-6 pl-10 pb-sm-0 pb-md-6">
          {{ $t("administration.administration") }}
        </div>
      </v-col>
      <v-col
        cols="12"
        md="8"
        lg="9"
        class="py-2 py-sm-4 pl-sm-6 pt-sm-2 pt-md-4 pl-md-0 overflow-x-auto"
      >
        <div class="d-inline-flex h-full">
          <CommonButton
            v-for="(item, index) in subMenuItems"
            :key="'subMenuItemsKey' + index"
            text
            :class="selectedButton(item)"
            @click="goAdminRoute(item)"
            color="rgba(55, 55, 55, 0.6)"
          >
            {{ item.title }}
            <span
              v-if="selectedItemProp === item.name"
              class="underline"
            ></span>
          </CommonButton>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters style="height: 100vh">
      <v-col lg="3" sm="4" md="4" cols="12" v-if="selectItemName !== ''">
        <v-card class="rounded-0 h-full">
          <v-list color="#2c3e50" class="h-full">
            <v-list-group
              v-for="(item, index) in this.leftMenuItems"
              :key="'leftMenuItems-' + index + '-' + item.name"
              v-model="item.active"
              append-icon=""
              no-action
              :id="'leftMenuId-' + item.name"
              @click="resetIcons(item)"
            >
              <template v-slot:activator v-if="item.items && item.items.length">
                <v-list-item-content>
                  <v-list-item-title
                    :class="getFontColorForSelectedChild(item)"
                  >
                    <v-icon
                      class="ml-5 pr-2"
                      :color="getIconColorForSelectedItem(item)"
                    >
                      {{ item.icon }}
                    </v-icon>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="white">{{ item.activatorIcon }}</v-icon>
                </v-list-item-icon>
              </template>

              <template v-slot:activator v-if="item.isDropdown">
                <v-list-item-content class="pl-5">
                  <CommonSelect
                    v-model="item.currentValue"
                    :items="item.dropdownItems"
                    :item-text="item.dropdownText"
                    :item-value="item.dropdownValue"
                    id-name-ref="administration_layout_left_menu_item_options"
                    @change="onDropdownItemClick"
                    return-object
                    class="select-dropdown"
                    solo
                    :menu-props="{ contentClass: 'dropdown-menu' }"
                    cache-items
                  ></CommonSelect>
                </v-list-item-content>
              </template>

              <template v-slot:activator v-else>
                <v-list-item-content>
                  <v-list-item-title
                    @click="emit(item.name)"
                    :class="getFontColorForSelectedChild(item)"
                  >
                    <v-icon
                      class="ml-5 pr-2"
                      :color="getIconColorForSelectedItem(item)"
                    >
                      {{ item.icon }}
                    </v-icon>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="white">{{ item.activatorIcon }}</v-icon>
                </v-list-item-icon>
              </template>

              <v-list-item
                v-for="(child, childIndex) in item.items"
                :key="'childMenuItem-' + index + '-' + child.name"
                class="cursor-pointer"
                @click="emit(child.name)"
                :id="'childMenuId-' + item.name + '-' + child.name"
              >
                <span class="tree-view-line-bottom" />
                <span
                  class="tree-view-line-height"
                  v-if="childIndex + 1 !== item.items.length"
                />
                <v-list-item-content>
                  <v-list-item-title :class="getFontColorForSelected(child)">{{
                    child.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        :lg="selectItemName == '' ? 12 : 9"
        :md="selectItemName == '' ? 12 : 8"
        :sm="selectItemName == '' ? 12 : 8"
      >
        <slot />
      </v-col>
    </v-row>
  </ContentPane>
</template>
<script>
const INACTIVE_ICON_COLOR = "#a7b4bb";
const ACTIVE_ICON_COLOR = "primary";
const CSS_WHITE_TEXT = "primary--text";
const CSS_TEXT_COLOR_PRIMARY = "primary--text";
const CSS_INACTIVE_TEXT = "inactiveText";
export default {
  name: "Administration",
  components: {},
  props: ["selectedItemProp", "leftMenuItems", "selectItemName"],
  data() {
    return {
      title: this.$t("administration.administration"),
      subMenuItems: [
        {
          title: this.$t("administration.collections.collections"),
          route: "Administration Collections",
          name: "Collections",
        },
        {
          title: this.$t("administration.procedures.procedures"),
          route: "Administration Procedures",
          name: "Procedures",
        },
        {
          title: this.$t("administration.products.products"),
          route: "Administration Products",
          name: "Products",
        },
        {
          title: this.$t("administration.testing.testing"),
          route: "Administration Testing",
          name: "Testing",
        },
        {
          title: this.$t("administration.sales.sales"),
          route: "Administration Sales",
          name: "Sales",
        },
        {
          title: this.$t("administration.userManagement.userManagement"),
          route: "Administration User Management",
          name: "User Management",
        },
        {
          title: this.$t("administration.system.system"),
          route: "Administration System",
          name: "System",
        },
        {
          title: this.$t(
            "administration.configurationManagement.configurationManagement"
          ),
          route: "Administration Configuration Management",
          name: "Configuration Management",
        },
      ],
      selectedMenuItem: "",
    };
  },
  methods: {
    selectedButton(item) {
      if (this.selectedItemProp === item.name) {
        return CSS_TEXT_COLOR_PRIMARY;
      }
      return "";
    },

    goAdminRoute(item) {
      if (this.selectedItemProp !== item.name) {
        this.$routerWrapper.push({ name: item.route });
      }
    },

    resetIcons(item) {
      for (let i = 0; i < this.leftMenuItems.length; i++) {
        let currentItem = this.leftMenuItems[i];
        if (currentItem.name === item.name) {
          if (currentItem.activatorIcon === currentItem.activatedIcon) {
            currentItem.activatorIcon = currentItem.unactivatedIcon;
          } else {
            currentItem.activatorIcon = currentItem.activatedIcon;
          }
        } else {
          currentItem.activatorIcon = currentItem.unactivatedIcon;
        }
      }
    },

    emit(name) {
      this.selectedMenuItem = name;
      this.$emit("updateSelection", name);
    },

    getFontColorForSelectedChild(item) {
      if (item.items === undefined) {
        if (
          this.selectedMenuItem === item.name ||
          this.selectItemName === item.name
        ) {
          return CSS_WHITE_TEXT;
        }
        return CSS_INACTIVE_TEXT;
      }
      for (let i = 0; i < item.items.length; i++) {
        if (
          this.selectedMenuItem === item.items[i].name ||
          this.selectItemName === item.items[i].name
        ) {
          return CSS_WHITE_TEXT;
        }
      }
      return CSS_INACTIVE_TEXT;
    },
    getFontColorForSelected(item) {
      if (
        this.selectedMenuItem === item.name ||
        this.selectItemName === item.name
      ) {
        return CSS_WHITE_TEXT;
      }
      return CSS_INACTIVE_TEXT;
    },

    getIconColorForSelectedItem(item) {
      if (item.items === undefined) {
        if (this.selectedMenuItem === item.name) {
          return ACTIVE_ICON_COLOR;
        }
        return INACTIVE_ICON_COLOR;
      }
      for (let i = 0; i < item.items.length; i++) {
        if (
          this.selectedMenuItem === item.items[i].name ||
          this.selectItemName === item.items[i].name
        ) {
          return ACTIVE_ICON_COLOR;
        }
      }

      return INACTIVE_ICON_COLOR;
    },
    onDropdownItemClick(item) {
      this.$emit("dropdownItemClick", this.selectItemName, item);
    },
  },
  beforeMount() {
    if (typeof this.selectItemName == "undefined") {
      this.selectedMenuItem = this.selectItemName = "Summary";
    }
  },
};
</script>
<style lang="scss">
#admin-layout {
  .dropdown-menu {
    margin-top: 44px;
    box-shadow: none;

    .v-list-item--link::after {
      display: inline-block;
      content: "";
      border-bottom: 1px solid #dfe6e8;
      width: 96%;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    }

    .v-select-list {
      .v-list-item:hover::before {
        opacity: 0.3;
      }

      .v-list-item--active {
        caret-color: transparent !important;
        color: inherit !important;

        &:before {
          opacity: 0;
        }

        .v-list-item__content .v-list-item__title {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.select-dropdown::v-deep {
  .v-input__slot {
    background-color: #2c3e50 !important;
    box-shadow: none !important;
    padding: 0 0 0 12px !important;

    .v-select__selections {
      line-height: 22px;
    }
  }

  .v-text-field__details {
    margin-bottom: 0px;
  }

  .v-select__selections,
  .v-icon {
    color: #ffffff !important;
  }

  .v-select__selection {
    font-size: 1.125rem;
  }

  .v-text-field__details {
    min-height: 0;
    height: 0;
  }
}

.inactiveText {
  color: #6c7885;
}

.tree-view-line-bottom {
  border-left: #6c7885 2px solid;
  border-bottom: #6c7885 2px solid;
  height: 49px;
  width: 20px;
  position: absolute;
  top: -25px;
  margin-left: -10px;
}

.tree-view-line-height {
  border-left: #6c7885 2px solid;
  height: 49px;
  width: 5px;
  position: absolute;
  margin-left: -10px;
}

.underline {
  width: 75%;
  border-bottom: #8ac417 4px solid;
  position: absolute;
  top: 30px;
}
</style>
