<template>
  <v-row no-gutters id="donor-location-label" class="pt-3 pin-wrapper d-flex">
    <div class="pin-location py-3 pr-10 rounded-r-xl">
      <span>{{
        $helperFunctions.getDisplayValue(locationsMap, selectedLocation)
      }}</span>
    </div>
  </v-row>
</template>
<script>
import { HelperFunctions, MnemonicService } from "@bbcsinc/common-vue";
import { mapGetters } from "vuex";

export default {
  name: "DonorLocation",
  props: {
    selectable: Boolean,
  },

  data() {
    return {
      selectedLocation: "",
      locations: [],
      locationsMap: {},
    };
  },
  computed: {},
  methods: {
    ...mapGetters("DonorManagement", ["getCollectionLocation"]),
  },
  mounted() {
    try {
      MnemonicService.get(MnemonicService.types.LOCATION).then((res) => {
        this.locations = res.data;
        this.locationsMap = HelperFunctions.createMnemonicMap(res.data);
        this.$set(this, "selectedLocation", this.getCollectionLocation());
      });
    } catch (error) {
      //eslint-disable-next-line
      console.error(error);
      //eslint-disable-next-line
    }
  },
};
</script>

<style scoped>
.pin-location {
  box-shadow: 0 6px 10px 0 rgba(43, 81, 140, 0.1);
  background: var(--alternate-color)
    url(../../../../assets/common-icons/pin-location.svg) no-repeat;
  background-position: 36px center;
  background-size: 16px;
  padding-left: 66px;
  min-width: 218px;
  font-size: 20px;
  text-transform: capitalize;
}
@media screen and (max-width: 1024px) {
  .pin-location {
    font-size: 14px !important;
    background-position: 20px center;
    background-size: 16px;
    padding-left: 46px;
    min-width: auto;
    font-size: 20px;
    padding-right: 20px !important;
  }
}
@media screen and (max-width: 600px) {
  .pin-location {
    line-height: 14px;
    background-position: 12px center;
    background-size: 10px;
    padding-left: 30px;
    min-width: 100px;
    padding-right: 10px !important;
    white-space: nowrap;
  }
  .pin-location span {
    font-size: 12px;
  }
}
</style>
