import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import App from "./App.vue";
import BlockUI from "vue-blockui";

// router
import router from "./router";
import routerWrapper from "@/modules/main/services/routerWrapper.js";
import store from "./store";
import axios from "axios";

import DefaultLayout from "@/modules/main/layouts/DefaultLayout";
import ContentPane from "@/modules/main/layouts/components/ContentPane";
import ContentPaneV2 from "@/modules/main/layouts/components/ContentPaneV2";
import Administration from "@/modules/administration/layouts/AdministrationLayout";
import AppHeader from "@/modules/main/layouts/components/AppHeader";
import AppFooter from "@/modules/main/layouts/components/AppFooter";
import Loader from "@/modules/main/components/Loader";
import ToolTip from "@/modules/main/components/ToolTip";
import ProceedOrCancel from "@/modules/main/components/ProceedOrCancel";
import SaveChangesDialog from "@/modules/main/components/SaveChangesDialog";
import AuthImage from "@/modules/main/components/AuthImage";
// language
import VueI18n from "vue-i18n";
import i18n from "./i18n";
// heap analytics
import VueHeap from "./vueheap";
import VueTheMask from "vue-the-mask";

// Mixins
import Utils from "@/mixins/Utils";
import ErrorHandler from "@/mixins/ErrorHandler";

import interceptorsSetup from "@/modules/main/services/interceptors";
import commonVue, {
  HelperFunctions,
  KeycloakService,
} from "@bbcsinc/common-vue";

interceptorsSetup();

const version = {
  major: 0,
  minor: 9,
};

VueHeap.install({
  key: import.meta.env.VITE_HEAP_KEY,
  enable: import.meta.env.VITE_HEAP_ENABLE,
}); // adds Vue.prototype.$heap
Vue.prototype.$http = axios; //axios is not a vue plugin, so can't use Vue.use
// axios.defaults.baseURL = process.env.VITE_APP_API_BASEURL // defined in interceptors
Vue.prototype.$routerWrapper = routerWrapper;
Vue.prototype.$version = version;
Vue.prototype.$helperFunctions = HelperFunctions;
Vue.config.productionTip = false;
Vue.config.devtools = true;
/** Vue uses */
Vue.use(commonVue);
Vue.use(VueTheMask);
Vue.use(VueI18n);
Vue.use(ContentPane);
Vue.use(ContentPaneV2);
Vue.use(Administration);
Vue.use(AppHeader);
Vue.use(AppFooter);
Vue.use(Loader);
Vue.use(ToolTip);
Vue.use(SaveChangesDialog);
Vue.use(ProceedOrCancel);
Vue.use(AuthImage);
Vue.component("Loader", Loader);
Vue.component("ToolTip", ToolTip);
Vue.component("ProceedOrCancel", ProceedOrCancel);
Vue.component("SaveChangesDialog", SaveChangesDialog);
Vue.component("ContentPane", ContentPane);
Vue.component("ContentPaneV2", ContentPaneV2);
Vue.component("Administration", Administration);
Vue.component("AppHeader", AppHeader);
Vue.component("default-layout", DefaultLayout);
Vue.component("AuthImage", AuthImage);
Vue.use(BlockUI);

Vue.mixin(Utils);
Vue.mixin(ErrorHandler);

axios.defaults.headers.common["Accept-Language"] = i18n.locale;
sessionStorage.setItem("preferred-language", i18n.locale);

const getSubdomain = (url) => {
  url = url.replace("https://www.", "");
  url = url.replace("http://www.", "");
  url = url.replace("https://", "");
  url = url.replace("http://", "");
  var temp = url.split("/");

  if (temp.length > 0) {
    var temp2 = temp[0].split(".");
    if (temp2.length >= 2) {
      return temp2[0];
    } else {
      return "";
    }
  }
  return "";
};

const REALM_FROM_URL = getSubdomain(location.href);
if (!REALM_FROM_URL) {
  alert("Customer not found!");
} else {
  let initOptions = {
    realm: REALM_FROM_URL,
  };

  KeycloakService.startKeycloak(
    {
      vuetify,
      store,
      i18n,
      router,
      render: (h) => h(App),
    },
    initOptions
  );
}
