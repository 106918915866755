export const whlsecurity = {
  namespaced: true,
  state: {
    securityMap: "",
    currentUser: "",
    allowNav: false,
  },
  actions: {},
  mutations: {
    setSecurity(/*state*/) {
      // let user = sessionStorage.getItem('activeuser');
      //
      // if (state.securityMap === "" || state.currentUser !== user) {
      //     state.currentUser = user
      //     let data = {};
      //     let securityArray = JSON.parse(sessionStorage.user).userPrincipal.authorities;
      //     for (var i = 0; i < securityArray.length; i++) {
      //         let functionNumber = securityArray[i].authority.slice(6, 9).replace(":", "");
      //         let secLevel = securityArray[i].authority.charAt(securityArray[i].authority.length - 1);
      //         data[functionNumber] = secLevel;
      //     }
      //     state.securityMap = data;
      // }
    },
    setAllowNav(state, data) {
      state.allowNav = data;
    },
  },
  getters: {
    getSecLevel: (state) => (functionNumber) => {
      let retVal = state.securityMap[functionNumber];
      if (retVal == undefined) {
        retVal = 0;
      }
      return retVal;
    },
  },
};
