<template>
  <v-container fluid class="pa-0">
    <v-row v-if="shouldShowTopButtons" transition="scale-transition">
      <v-col align="center">
        <slot name="topSide"></slot>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-toolbar flat extended class="toolbar">
      <slot name="leftSide"></slot>
      <v-spacer></v-spacer>
      <slot name="centerSide"></slot>
      <v-spacer></v-spacer>
      <slot name="rightSide"></slot>
    </v-toolbar>
  </v-container>
</template>

<script>
export default {
  name: "ButtonBar",
  props: {
    showTopButtons: {
      default: true,
      type: Boolean,
    },
  },

  computed: {
    hasCenterSlot() {
      return !!this.$slots["centerSide"];
    },
    shouldShowTopButtons() {
      return !!this.$slots["topSide"] && this.showTopButtons;
    },
  },
};
</script>

<style scoped>
.toolbar {
  padding-top: 20px !important;
}
</style>
