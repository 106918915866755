<template>
  <v-snackbar
    bottom
    rounded="lg"
    :color="color"
    :timeout="timeout"
    v-model="snackbar"
  >
    <v-icon medium left>{{ icon }}</v-icon
    >{{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        fab
        small
        icon
        color="#2b518c"
        v-bind="attrs"
        @click="snackbar = false"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import ErrorHandler from "@/mixins/ErrorHandler";

export default {
  name: "Toast",
  mixins: [ErrorHandler],

  data() {
    return {
      snackbar: false,
      message: "",
      color: "success",
      icon: "mdi-check",
      timeout: 3000,
    };
  },
  methods: {
    show(data) {
      let message = "";
      if (typeof data.message == "string") message = data.message;
      else if (
        typeof data.message == "object" &&
        Object.keys(data.message).length
      ) {
        const keys = Object.keys(data.message);
        const keyword = keys[0].replace(/([a-z])([A-Z])/g, "$1 $2");
        message = `${keyword.charAt(0).toUpperCase() + keyword.slice(1)}: ${
          data.message[keys[0]][0]
        }`;
      }
      this.message = message || this.$t("messages.UNKNOWN_ERROR");
      this.color = data.color || "success";
      this.timeout = data.timeout || 3000;
      this.icon = data.icon || "mdi-check";
      this.snackbar = true;
    },
  },
};
</script>
