import { HelperFunctions, MnemonicService } from "@bbcsinc/common-vue";
import i18n from "../i18n.js";

export default {
  data() {
    return {
      fieldErrors: {}, //fieldErrors.field
      rowErrors: {}, //rowErrors[key].field
      errorList: [],
      validErrorFields: [],
    };
  },
  methods: {
    itemHasError(key) {
      return this.rowErrors.hasOwnProperty(key);
    },
    async handleErrors(errorData, clearPreviousErrors = true) {
      if (clearPreviousErrors) {
        this.clearErrors();
      }
      await this.mapErrorsToMapAndList(
        this.fieldErrors,
        this.errorList,
        errorData,
        this.validErrorFields
      );
    },
    clearErrors() {
      this.$set(this, "fieldErrors", {});
      this.$set(this, "rowErrors", {});
      this.errorList.splice(0);
    },
    clearErrorMessage(ele) {
      if (typeof ele == "string" && this.fieldErrors[ele] !== null) {
        delete this.fieldErrors[ele];
      }
    },
    async mapErrorsToMapAndList(
      fieldErrorMap,
      overflowErrorList,
      apiErrorObject,
      validFieldErrors
    ) {
      HelperFunctions.loopEveryErrorFieldCallMethod(
        apiErrorObject,
        async (errorId, errorField, errorInstance) => {
          let message = await this.getLocaleErrorFromErrorId(
            errorId,
            errorInstance
          );
          if (validFieldErrors.includes(errorField)) {
            if (!fieldErrorMap.hasOwnProperty(errorField)) {
              this.$set(fieldErrorMap, errorField, [message]);
            } else {
              if (Array.isArray(fieldErrorMap[errorField])) {
                fieldErrorMap[errorField].push(message);
              } else {
                let temp = fieldErrorMap[errorField];
                this.$set(fieldErrorMap, errorField, [temp]);
              }
            }
          } else {
            overflowErrorList.push({
              msgId: errorId,
              msg: message,
              isOverridable:
                errorInstance.isOverridable !== undefined
                  ? errorInstance.isOverridable
                  : apiErrorObject[errorId].isOverridable,
              keyMap: errorInstance.keyMap,
            });
          }

          let heapErrorDetails = {
            "Error Id": errorId,
            Fields: errorInstance.fields.reduce((a, b) => a + ", " + b),
            ...errorInstance.keyMap,
          };
          this.$heap.track("BBCS-Error", heapErrorDetails);
          this.addToRowErrors(errorField, errorInstance, message);
        }
      );
      return Promise.resolve();
    },

    async getLocaleErrorFromErrorId(msgId, errorInstance) {
      let errorId = msgId + "";
      if (errorId.includes("-")) {
        errorId = errorId.split("-")[1];
      }

      let errorMessage = i18n.t("messages." + errorId);
      if (
        errorInstance &&
        errorInstance["errorFillIns"] &&
        errorInstance["errorFillIns"].length > 0
      ) {
        let fillIns = errorInstance["errorFillIns"];

        for (let i = 0; i < fillIns.length; ++i) {
          let fillInObject = fillIns[i];
          if (
            fillInObject.mnemonicType === undefined ||
            fillInObject.mnemonicType === -1
          ) {
            errorMessage = errorMessage.replace(
              "{" + i + "}",
              fillInObject.fillIn
            );
          } else {
            try {
              let response = await MnemonicService.getMap(
                fillInObject.mnemonicType
              );
              let displayValue = fillInObject.fillIn;
              let mnemonic = response.data[fillInObject.fillIn];
              if (mnemonic && displayValue) {
                displayValue = mnemonic.displayValue;
              }
              errorMessage = errorMessage.replace("{" + i + "}", displayValue);
            } catch (e) {
              /* eslint-disable no-console */
              console.error("ErrorHandler mnemonic lookup failed, using code.");
              console.log(e);
              /* eslint-enable no-console */
              errorMessage = errorMessage.replace(
                "{" + i + "}",
                fillInObject.fillIn
              );
            }
          }
        }
      }
      return errorMessage;
    },

    async addToFieldError(field, msgId, errorInstance) {
      const message = await this.getLocaleErrorFromErrorId(
        msgId,
        errorInstance
      );
      this.$set(this.fieldErrors, field, message);
    },

    addToRowErrors(errorField, errorInstance, message) {
      let keyMap = errorInstance["keyMap"];
      let size = Object.keys(keyMap).length;
      if (size > 0) {
        let finalKey = this.buildKey(keyMap);
        if (!this.rowErrors.hasOwnProperty(finalKey)) {
          this.$set(this.rowErrors, finalKey, {
            [errorField]: [
              {
                msg: message,
                isOverridable: errorInstance.isOverridable,
              },
            ],
          });
        } else if (!this.rowErrors[finalKey].hasOwnProperty(errorField)) {
          this.$set(this.rowErrors[finalKey], errorField, [
            {
              msg: message,
              isOverridable: errorInstance.isOverridable,
            },
          ]);
        } else {
          this.$set(this.rowErrors[finalKey], errorField, [
            ...this.rowErrors[finalKey][errorField],
            {
              msg: message,
              isOverridable: errorInstance.isOverridable,
            },
          ]);
        }
      }
    },
    clearDisplayableErrors() {
      this.errorList.splice(0, this.errorList.length);
    },
    // fillIns can be a list of strings or list of objects with keys {fillIn, mnemonicType}
    addDisplayableError(msgId, options = {}) {
      const isOverridable = options.isOverridable || false;
      const fillIns = options.fillIns || [];

      const errorInstance =
        (fillIns.length > 0 && {
          errorFillIns: fillIns.map((fillIn) => {
            if (!Object.keys(fillIn).length > 0) {
              return { fillIn };
            }
            return fillIn;
          }),
        }) ||
        null;

      this.getLocaleErrorFromErrorId(msgId, errorInstance).then((message) => {
        this.errorList.push({
          msgId: msgId,
          msg: message,
          isOverridable,
        });
      });
    },
    buildKey(keyMap) {
      let finalKey = "";
      Object.keys(keyMap).forEach(function (key) {
        finalKey += keyMap[key];
      });
      return finalKey;
    },
  },
};

// throw this instead generic Error
export class BBCSException extends Error {
  constructor({ code, message }) {
    super();
    this.code = code;
    this.message = message || "An error ocurred. BBCS-" + code;
  }
}
