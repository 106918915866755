<template>
  <img :src="imgSrc" v-bind="$attrs" />
</template>

<script>
import axios from "axios";
import { Buffer } from "buffer";

export default {
  props: ["src"],
  data() {
    return { imgSrc: this.src };
  },
  methods: {
    setImgSrc() {
      if (!this.src || this.src === undefined || this.src.startsWith("/src/")) {
        return;
      }
      const imageUrl = this.src;

      axios({
        method: "get",
        url: imageUrl,
        responseType: "arraybuffer",
      })
        .then((resp) => {
          const mimeType = resp.headers["content-type"].toLowerCase();
          const imgBase64 = Buffer.from(resp.data, "binary").toString("base64");
          this.imgSrc = "data:" + mimeType + ";base64," + imgBase64;
        })
        .catch(() => {
          this.imgSrc = imageUrl;
        });
    },
  },
  watch: {
    src() {
      this.setImgSrc();
    },
  },
  mounted() {
    this.setImgSrc();
  },
};
</script>
