import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);
const DONORS = "Donor Hub";
const PATIENTS = "Patient Hub";
const QUALITY = "Quality";
const BILLING = "Billing Hub";
const COLLECTIONS = "Collections";

const routes = [
  {
    path: "/",
    redirect: "/cloud-dashboard",
    name: "DashboardRedirect",
    meta: { public: true, disableBack: true },
    component: () => import("@/modules/main/views/AboCloudDashboard.vue"),
  },
  {
    path: "/cloud-dashboard",
    name: "Cloud Dashboard",
    meta: {
      public: true,
      disableBack: true,
      titleKey: "whlmain.dashboard.title",
    },
    component: () => import("@/modules/main/views/AboCloudDashboard.vue"),
  },
  {
    path: "/whldonor/donors",
    name: DONORS,
    meta: {
      public: true,
      disableBack: true,
      titleKey: "whldonor.donor-hub.donor-hub-search.title",
    },
    props: true,
    component: () => import("@/modules/donor/donor-hub/views/DonorHubSearch"),
  },
  {
    path: "/patients",
    name: PATIENTS,
    meta: {
      public: true,
      disableBack: true,
      titleKey: "modules.patient-hub.title",
    },
    props: true,
    component: () =>
      import("@/modules/patient/patient-hub/views/PatientHubSearch"),
  },
  {
    path: "/quality",
    name: QUALITY,
    meta: {
      public: true,
      disableBack: true,
      titleKey: "modules.quality-lookups.title",
    },
    props: true,
    component: () => import("@/modules/quality/views/QualityLookups"),
  },
  {
    path: "/duplicate-donor-processing",
    name: "Duplicate Donor Processing",
    meta: {
      public: true,
      disableBack: false,
      titleKey: "modules.quality-lookups.duplicate-donor-processing.title",
    },
    props: true,
    component: () => import("@/modules/quality/views/DuplicateDonorProcessing"),
  },
  {
    path: "/duplicate-donor-processing-detail",
    name: "Duplicate Donor Processing Detail",
    meta: {
      public: true,
      disableBack: false,
      titleKey:
        "modules.quality-lookups.duplicate-donor-processing-detail.title",
    },
    props: true,
    component: () =>
      import("@/modules/quality/views/DuplicateDonorProcessingDetail"),
  },
  {
    path: "/billing",
    name: BILLING,
    meta: {
      public: true,
      disableBack: true,
      titleKey: "modules.billing-hub.title",
    },
    props: true,
    component: () =>
      import("@/modules/billing/billing-hub/views/BillingHubView"),
  },
  {
    path: "/inventory",
    name: "Sales Hub",
    meta: {
      public: true,
      disableBack: true,
      titleKey: "inventory.available-inventory.title",
    },
    props: true,
    component: () => import("@/modules/inventory/views/AvailableInventory"),
  },
  {
    path: "/reports/savedSearches",
    name: "Saved Search",
    meta: {
      public: true,
      disableBack: true,
      titleKey: "reports.savedSearches.title",
    },
    props: true,
    component: () =>
      import("@/modules/reports/saved-searches/views/SavedSearches.vue"),
  },
  {
    path: "/reports/scorecards",
    name: "Scorecards",
    meta: {
      public: true,
      disableBack: true,
      titleKey: "reports.scorecards.title",
    },
    props: true,
    component: () =>
      import(
        "@/modules/reports/scorecards/components/ScorecardKpiCalculationComponent.vue"
      ),
  },
  {
    path: "/products/:location?",
    name: "Product Hub",
    meta: {
      public: true,
      disableBack: true,
      titleKey: "product-hub.products.title",
    },
    props: true,
    component: () => import("@/modules/product/product-hub/views/ProductHub"),
  },
  {
    path: "/product-details",
    name: "Product Details",
    meta: {},
    props: true,
    component: () =>
      import("@/modules/donor/donor-hub/product-details/views/ProductDetail"),
  },
  {
    path: "/product-hub/product-modification",
    name: "Product Modification",
    meta: { titleKey: "product-hub.productModification.title" },
    props: true,
    component: () =>
      import(
        "@/modules/product/product-hub/product-modification/views/ProductModify"
      ),
  },
  {
    path: "/product-hub/pooling",
    name: "Pooling",
    meta: { titleKey: "product-hub.pooling.heading" },
    props: true,
    component: () =>
      import("@/modules/product/product-hub/pooling/views/ProductPooling"),
  },
  {
    path: "/product-hub/product-import",
    name: "Product Import",
    meta: {
      public: true,
      titleKey: "product-hub.product-import.title",
    },
    props: true,
    component: () =>
      import(
        "@/modules/product/product-hub/product-import/views/ProductImport"
      ),
  },
  {
    path: "/product-hub/final-labeling",
    name: "Final Labeling",
    meta: {
      public: true,
      titleKey: "modules.product.labeling.final-labeling.title",
    },
    props: true,
    component: () => import("@/modules/product/labeling/views/FinalLabeling"),
  },
  {
    path: "/lot-release",
    name: "Lot Release",
    meta: { titleKey: "product-hub.lot-release.creation.title" },
    props: true,
    component: () =>
      import("@/modules/product/product-hub/lot-release/views/LotRelease"),
  },
  {
    path: "/lot-workbench",
    name: "Lot Workbench",
    meta: { titleKey: "product-hub.lot-release.inquiry.title" },
    props: true,
    component: () =>
      import("@/modules/product/product-hub/lot-release/views/LotWorkbench"),
  },
  {
    path: "/lot-summary/:lotId",
    name: "Lot Release Summary",
    meta: {
      public: true,
      titleKey: "product-hub.lot-release-summary.title",
    },
    props: true,
    component: () =>
      import(
        "@/modules/product/product-hub/lot-release/views/LotReleaseSummary"
      ),
  },
  {
    path: "/product-testing",
    name: "Product Testing",
    meta: { titleKey: "product-hub.product-testing.title" },
    props: true,
    component: () =>
      import(
        "@/modules/product/product-hub/product-testing/views/UnitProfileTests"
      ),
  },
  {
    path: "/unit-testing-search",
    name: "Unit Testing Search",
    meta: { titleKey: "product-hub.product-testing.DonorTesting.title" },
    props: true,
    component: () =>
      import(
        "@/modules/product/product-hub/product-testing/views/DonorTesting"
      ),
  },
  {
    path: "/unit-testing-search/single-test",
    name: "Unit Testing Single Test",
    meta: { titleKey: "product-hub.product-testing.single-test.title" },
    props: true,
    component: () =>
      import("@/modules/product/product-hub/product-testing/views/SingleTest"),
  },
  {
    path: "/unit-testing-search/all-tests",
    name: "Unit Testing All Tests",
    meta: { titleKey: "product-hub.product-testing.all-test.title" },
    props: true,
    component: () =>
      import("@/modules/product/product-hub/product-testing/views/AllTests"),
  },
  // {
  //     path: '/unit-testing-single-test',
  //     name: 'Unit Testing Single Test',
  //     meta: {},
  //     props: true,
  //     component: () => import('@/modules/product/product-hub/product-testing/views/UnitTestingSingleTest')
  // },
  {
    path: "/whldonor/donor-inquiry/:donor",
    name: "Donor Inquiry",
    props: (route) => ({
      donor: route.params.donor,
      startPageIndex: Number.parseInt(route.query.startPageIndex, 0),
    }),
    meta: {
      public: true,
      titleKey: "whldonor.donor-hub.tabs.title",
    },
    component: () => import("@/modules/donor/donor-hub/views/DonorInquiry.vue"),
  },
  {
    path: "/donor-unit-details/:unit",
    name: "Donor Unit Details",
    meta: {
      public: true,
      titleKey: "whldonor.donor-hub.unit-detail.title",
    },
    props: true,
    component: () =>
      import("@/modules/donor/donor-hub/unit-details/views/UnitDetails"),
  },
  {
    path: "/donor-room/:location?",
    name: COLLECTIONS,
    props: true,
    meta: {
      public: true,
      disableBack: true,
      titleKey: "whldnrmgmt.queue.title",
    },
    component: () =>
      import(
        /* webpackChunkName: "donorRoom" */ "@/modules/donor/management/views/DonorRoomManagement.vue"
      ),
  },
  {
    path: "/whldonor/donor-selection",
    name: "Donor Search",
    meta: {
      titleKey: "modules.donor.demographic.donor-selection-screen.header",
    },
    props: true,

    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/demographic/views/DonorSelectionScreen.vue"
      ),
  },
  {
    path: "/whldonor/donor-search",
    name: "Donor Search Screen",
    meta: {
      titleKey: "modules.donor.demographic.donor-search-screen.header",
      language: "modules.donor.demographic.donor-search-screen.",
      searchType: "Donor",
    },
    props: true,

    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/demographic/views/DonorSearch.vue"
      ),
  },
  {
    path: "/reports/antibody-antigen",
    name: "Antibody-Antigen Donor Search Screen",
    meta: {
      titleKey: "modules.donor.demographic.donor-search-screen.header",
      language: "modules.donor.demographic.antibody-antigen-search.",
      searchType: "Antibody-Antigen Donor",
    },
    props: true,

    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/demographic/views/DonorSearch.vue"
      ),
  },
  {
    path: "/reports/antibody-antigen",
    name: "Antibody-Antigen Inventory Search Screen",
    meta: {
      titleKey: "modules.donor.demographic.donor-search-screen.header",
      language: "modules.donor.demographic.antibody-antigen-search.",
      searchType: "Antibody-Antigen Inventory",
    },
    props: true,

    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/demographic/views/DonorSearch.vue"
      ),
  },
  {
    path: "/reports/product-search",
    name: "Product Search Screen",
    meta: {
      titleKey: "modules.donor.demographic.donor-search-screen.header",
      language: "modules.donor.demographic.product-search.",
      searchType: "Product",
    },
    props: true,

    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/demographic/views/DonorSearch.vue"
      ),
  },
  {
    path: "/whldonor/donor-confirmation",
    name: "Donor Confirmation Screen",
    meta: {
      titleKey: "modules.donor.demographic.donor-confirmation-screen.title",
    },
    props: true,

    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/demographic/views/DonorConfirmationScreen.vue"
      ),
  },
  {
    path: "/whldonor/donor-demographic",
    name: "Donor Demographics",
    meta: { titleKey: "modules.donor.demographic.donor-demographic.title" },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/demographic/views/DonorDemographic.vue"
      ),
  },
  //TODO: remove? Appears to be an unused route

  // {
  //     path: '/whlreg/donor-detail-processing',
  //     name: 'Donor Detail Processing',
  //     component: () => import(/* webpackChunkName: "whlDonor" */ '@/modules/donor/registration/views/DonorDetailProcessing.vue')
  // },
  {
    path: "/whlprocselect/procedure-selection",
    name: "Procedure Selection Selection",
    meta: {
      public: true,
      titleKey: "modules.donor.procedure-selection.title",
    },
    component: () =>
      import(
        "@/modules/donor/procedure-selection/views/ProcedureSelectionScreen.vue"
      ),
  },
  {
    path: "/whlqpass/scan-questionnaire",
    name: "Scan Questionnaire",
    meta: {
      public: true,
      titleKey: "whlqpass.scanquestionnaire.title",
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/quickpass/views/ScanQuestionnaire.vue"
      ),
  },
  {
    path: "/whlqpass/confirm-questionnaire",
    name: "Confirm Questionnaire",
    meta: {
      public: true,
      titleKey: "whlqpass.confirmquestionnaire.title",
    },

    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/quickpass/views/ConfirmQuestionnaire.vue"
      ),
  },
  {
    path: "/whlreg/continue-questionnaire",
    name: "Continue Questionnaire",
    meta: {
      public: true,
      titleKey: "whldonor.continueQuestionnaireScreen.title",
    },
    component: () =>
      import(
        /* webpackChunkName: "donorRoom" */ "@/modules/donor/quickpass/views/ContinueQuestionnaireScreen"
      ),
  },
  {
    path: "/whlquestion/questionnaire-summary-screen",
    name: "Questionnaire Summary",
    props: true,
    meta: {
      public: true,
      titleKey: "whlqpass.questionnaireSummaryScreen.title",
    },
    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/question/views/QuestionnaireSummaryScreen.vue"
      ),
  },
  {
    path: "/whlquestion/questionnaire",
    name: "Questionnaire",
    props: true,
    meta: {
      public: true,
      titleKey: "whlqpass.questionnaire.title",
    },
    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/question/views/Questionnaire.vue"
      ),
  },
  {
    path: "/whldocsig/consent",
    name: "Donor Consent",
    props: true,
    meta: { titleKey: "whlqpass.consent.title" },
    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/document-signature/views/ConsentScreen.vue"
      ),
  },
  {
    path: "/whldonscrn/screening-detail",
    name: "Screening Detail Processing",
    meta: {
      public: true,
      titleKey: "whldonscrn.detailprocessing.title",
    },
    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/screening/views/ScreeningDetailProcessingScreen.vue"
      ),
  },
  {
    path: "/whlequip/equipment-qc-screen",
    name: "Equipment QC",
    meta: { titleKey: "whlequip.equipmentqc.title" },
    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/equipment/views/EquipmentQCScreen.vue"
      ),
  },
  {
    path: "/whldeferral/donor-deferral",
    name: "Donor Deferral",
    meta: { titleKey: "whldeferral.donordeferral.title" },
    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/deferral/views/DonorDeferralScreen.vue"
      ),
  },
  {
    path: "/whlPhlebot/phlebotomy-detail",
    name: "Phlebotomy Detail",
    meta: { titleKey: "whlphlebotomy.title" },
    component: () =>
      import(
        /* webpackChunkName: "whlDonor" */ "@/modules/donor/phlebotomy/views/PhlebotomyDetail.vue"
      ),
  },
  {
    path: "/whlcomment/comment-summary-screen",
    name: "Comment Summary",
    meta: { titleKey: "whlcomment.commentSummary" },
    component: () =>
      import("@/modules/donor/comment/views/CommentSummaryScreen.vue"),
  },
  {
    path: "/whlcomment/donor-history",
    name: "Donor History",
    meta: { titleKey: "whldonor.donor-hub.donor-history.title" },
    component: () => import("@/modules/donor/history/views/HealhHistory.vue"),
  },
  {
    path: "/whlcomment/comment-detail-screen",
    name: "Comment Detail",
    meta: { titleKey: "whlcomment.commentDetailScreen" },
    props: true,
    component: () =>
      import("@/modules/donor/comment/views/CommentDetailScreen"),
  },
  {
    path: "/whlcomment/create-comment-by-code",
    name: "Comment Maintenance",
    meta: { titleKey: "whlcomment.commentMaintenance" },
    props: true,
    component: () =>
      import("@/modules/donor/comment/views/CommentMaintenanceScreen"),
  },
  {
    path: "/whlsupply/received-supply",
    name: "Received Supply",
    meta: { titleKey: "whlsupply.supplyProvisioningScreen.receiveTitle" },
    props: true,
    component: () => import("@/modules/donor/supply/views/ReceivedSupply"),
  },
  {
    path: "/bulk-shipment",
    name: "Bulk Shipment",
    props: true,
    component: () =>
      import("@/modules/inventory/bulk-shipment/views/ActiveBulkShipments"),
  },
  {
    path: "/whlequip/equipment-detail",
    name: "Equipment",
    meta: { titleKey: "whlequip.detail.title" },
    component: () => import("@/modules/donor/equipment/views/EquipmentDetail"),
  },
  {
    path: "/whleligsum/eligibility-summary-screen",
    name: "Eligibility Summary",
    meta: { titleKey: "whleligsum.eligsummaryscreen.title" },
    props: true,
    component: () =>
      import("@/modules/donor/eligibility/views/EligibilitySummaryScreen"),
  },
  {
    path: "/whlattrib/attribute-detail-screen",
    name: "Attribute Detail Screen",
    meta: {
      public: true,
      verifySave: true,
      titleKey: "whlattrib.attributedetailscreen.title",
    },
    props: true,
    component: () =>
      import("@/modules/donor/attribute/views/AttributeDetailScreen"),
  },
  {
    path: "/whlprtulbl/unit-label-selection",
    name: "Unit Label Selection",
    meta: { titleKey: "whlprtulbl.unitlabelselection.title" },
    props: true,
    component: () =>
      import("@/modules/product/print-label/views/UnitLabelSelectionScreen"),
  },
  {
    path: "/whlprtulbl/unit-label-verification",
    name: "Unit Label Verification",
    meta: { titleKey: "whlprtulbl.unitlabelverification.title" },
    props: true,
    component: () =>
      import("@/modules/product/print-label/views/UnitLabelVerificationScreen"),
  },
  {
    path: "/whldnrmgmt/drive-status",
    name: "Drive Status",
    meta: { titleKey: "whldnrmgmt.driveStatusScreen.title" },
    component: () => import("@/modules/donor/management/views/DriveStatus"),
  },
  {
    path: "/whlmodreg/registration-maintenance",
    name: "Registration Maintenance",
    meta: { titleKey: "whlmodreg.registrationmaintenance.title" },
    component: () =>
      import(
        "@/modules/donor/registration-modification/views/RegistrationMaintenanceScreen"
      ),
  },
  {
    path: "/whldnrmgmt/appointment-management",
    name: "Appointment Management",
    meta: { titleKey: "whldnrmgmt.apptmgmt.title" },
    props: true,
    component: () =>
      import("@/modules/donor/management/views/AppointmentManagement"),
  },
  {
    path: "/whlPic/photo-capture",
    name: "Donor Photo Capture",
    meta: { titleKey: "whlPic.title" },
    props: true,
    component: () =>
      import("@/modules/donor/picture/views/DonorPhotoCapture.vue"),
  },
  {
    path: "/main/wheels-options",
    name: "Wheels Options",
    meta: { titleKey: "options.title" },
    props: false,
    component: () => import("@/modules/main/views/OptionsScreen.vue"),
  },
  {
    path: "/main/about",
    name: "About",
    meta: { titleKey: "about.title" },
    props: false,
    component: () => import("@/modules/main/views/AboutScreen.vue"),
  },
  {
    path: "/whlTransit/in-transit",
    name: "In Transit Hub",
    meta: { titleKey: "whlTransit.title" },
    props: true,
    component: () => import("@/modules/product/transit/views/InTransitHub.vue"),
  },
  {
    path: "/product-received",
    name: "Receiving Products",
    meta: { titleKey: "whlProductHub.productReceived.title" },
    props: true,
    component: () =>
      import("@/modules/product/product-hub/views/ReceivedProducts.vue"),
  },
  {
    path: "/whlattrib/attribute-maintenance-screen",
    name: "Attribute Maintenance Screen",
    meta: { titleKey: "whlattrib.attributeMaintenance.title" },
    props: true,
    component: () =>
      import("@/modules/donor/attribute/views/AttributeMaintenanceScreen.vue"),
  },
  {
    path: "/detailed-transactional-history",
    name: "Detailed Transactional History",
    meta: { titleKey: "whldonor.donor-hub.detailed-transaction-history.title" },
    props: true,
    component: () =>
      import(
        "@/modules/donor/donor-hub/unit-transaction-history/views/DetailedTransactionalHistory.vue"
      ),
  },
  {
    path: "/administration/collections",
    name: "Administration Collections",
    meta: {
      public: true,
      titleKey: "modules.administration.collections.title",
    },
    props: true,
    component: () =>
      import("@/modules/administration/collections/views/Collections.vue"),
  },
  {
    path: "/administration/procedures",
    name: "Administration Procedures",
    meta: {
      public: true,
      titleKey: "modules.administration.procedures.title",
    },
    props: true,
    component: () =>
      import("@/modules/administration/procedures/views/Procedures.vue"),
  },
  {
    path: "/administration/products",
    name: "Administration Products",
    meta: {
      public: true,
      titleKey: "modules.administration.products.title",
    },
    props: true,
    component: () =>
      import("@/modules/administration/products/views/Products.vue"),
  },
  {
    path: "/administration/testing",
    name: "Administration Testing",
    meta: {
      public: true,
      titleKey: "modules.administration.testing.title",
    },
    props: true,
    component: () =>
      import("@/modules/administration/testing/views/Testing.vue"),
  },
  {
    path: "/administration/sales",
    name: "Administration Sales",
    meta: {
      public: true,
      titleKey: "modules.administration.sales.title",
    },
    props: true,
    component: () => import("@/modules/administration/sales/views/Sales.vue"),
  },
  {
    path: "/administration/user-management",
    name: "Administration User Management",
    meta: {
      public: true,
      titleKey: "modules.administration.user-management.title",
    },
    props: true,
    component: () =>
      import(
        "@/modules/administration/user-management/views/UserManagement.vue"
      ),
  },
  {
    path: "/administration/system",
    name: "Administration System",
    meta: {
      public: true,
      titleKey: "modules.administration.system.title",
    },
    props: true,
    component: () => import("@/modules/administration/system/views/System.vue"),
  },
  {
    path: "/administration/configuration-management",
    name: "Administration Configuration Management",
    meta: {
      public: true,
      titleKey: "modules.administration.configuration-management.title",
    },
    props: true,
    component: () =>
      import(
        "@/modules/administration/configuration-managements/views/ConfigurationManagement.vue"
      ),
  },
  {
    path: "/product-hub/interim-final-labeling",
    name: "Interim Final Labeling",
    meta: {
      public: true,
      titleKey: "modules.product.labeling.final-labeling.title",
    },
    props: true,
    component: () =>
      import("@/modules/product/labeling/views/InterimFinalLabeling"),
  },
  {
    path: "/orders",
    name: "Orders",
    meta: {
      public: true,
      titleKey: "modules.inventory.orders.title",
    },
    props: true,
    component: () => import("@/modules/inventory/orders/views/Orders.vue"),
  },
  {
    path: "/order-search",
    name: "Order Search",
    props: (route) => ({
      from: route.params.from,
      to: route.params.to,
      customerCodes: route.params.customerCodes,
      customerLocationCodes: route.params.customerLocationCodes,
      laboratoryCodes: route.params.laboratoryCodes,
      startPageIndex: Number.parseInt(route.query.startPageIndex, 0),
    }),
    meta: {
      public: true,
      titleKey: "modules.inventory.orderSearch.title",
    },
    component: () => import("@/modules/inventory/orders/views/OrderSearch.vue"),
  },
  {
    path: "/new-order",
    name: "New Order",
    meta: {
      public: true,
      titleKey: "modules.inventory.new-order.title",
    },
    props: true,
    component: () =>
      import("@/modules/inventory/orders/components/CreateOrUpdateOrder.vue"),
  },
  {
    path: "/order/:order",
    name: "Update Order",
    meta: {
      public: true,
      titleKey: "modules.inventory.orders.order",
    },
    props: true,
    component: () =>
      import("@/modules/inventory/orders/components/CreateOrUpdateOrder.vue"),
  },
  {
    path: "/order-search-details/:order",
    name: "Order Search Details",
    props: (route) => ({ order: route.params.order }),
    meta: {
      public: true,
      titleKey: "modules.inventory.new-order.title",
    },
    component: () =>
      import("@/modules/inventory/orders/views/OrderSearchDetails.vue"),
  },
  {
    path: "/pack-order/:order",
    name: "Pack Order",
    props: (route) => ({ order: route.params.order }),
    meta: {
      public: true,
      titleKey: "modules.inventory.pack-order.title",
    },
    component: () => import("@/modules/inventory/orders/views/PackOrder.vue"),
  },
  {
    path: "/shipments",
    name: "Shipments",
    props: true,
    meta: {
      public: true,
      titleKey: "modules.inventory.shipments.title",
    },
    component: () =>
      import("@/modules/inventory/shipments/views/Shipments.vue"),
  },

  //patients pages routes
  {
    path: "/patients/patient-profile/:patient",
    name: "Patient Inquiry",
    props: (route) => ({
      patient: route.params.patient,
      startPageIndex: Number.parseInt(route.query.startPageIndex, 0),
    }),
    meta: {
      public: true,
      titleKey: "modules.patient-hub.profile.title",
    },
    component: () =>
      import("@/modules/patient/patient-hub/views/PatientInquiry.vue"),
  },
  {
    path: "/patients/patient-selection",
    name: "Patient Search",
    meta: { titleKey: "modules.patient-hub.patient-selection-screen.header" },
    props: true,
    component: () =>
      import("@/modules/patient/patient-hub/views/PatientSelectionScreen.vue"),
  },
  {
    path: "/patients/patient-confirmation",
    name: "Patient Confirmation",
    meta: {
      public: true,
      titleKey: "modules.patient-hub.confirmation.title",
    },
    props: true,
    component: () =>
      import("@/modules/patient/patient-hub/views/PatientConfirmation.vue"),
  },
  {
    path: "/inventory/bulk-shipment-box",
    name: "Bulk Shipment Box",
    props: (route) => ({
      data: route.params.data,
    }),
    meta: {
      public: true,
      titleKey: "modules.inventory.bulk-shipment.bulk-shipment-box-modal.title",
    },
    component: () =>
      import(
        "@/modules/inventory/bulk-shipment/views/BulkShipmentBoxModal.vue"
      ),
  },
  {
    path: "/inventory/bulk-shipment-audit-box",
    name: "Bulk Shipment Audit Box",
    props: (route) => ({
      data: route.params.data,
      products: route.params.products,
    }),
    meta: {
      public: true,
      titleKey: "modules.inventory.bulk-shipment.bulk-shipment-box-modal.title",
    },
    component: () =>
      import(
        "@/modules/inventory/bulk-shipment/views/BulkShipmentAuditBox.vue"
      ),
  },
  {
    path: "/quality/lookback-workbench",
    name: "Lookback Workbench",
    props: true,
    component: () =>
      import("@/modules/quality/lookback-workbench/views/LookbackWorkbench"),
  },
  //catch all - must be last entry
  {
    path: "*",
    name: "404",
    meta: { public: true },
    component: () => import("@/modules/main/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// Update BreadCrumb if back button is selected in browser.
let isBrowserButtonClicked = false;
window.addEventListener("popstate", () => {
  isBrowserButtonClicked = true;
});

router.beforeEach((to, from, next) => {
  const allowNav = store.state.whlsecurity.allowNav;
  const DisabledBrowserButtonScreens = from.matched.some(
    (record) => record.meta.disableBack
  );
  if (to.name === DONORS) {
    store.commit("leftnav/setDonorDemoNext", false);
  } else if (to.name === COLLECTIONS) {
    store.commit("leftnav/setDonorDemoNext", true);
  }

  //const SaveOptionScreen = from.matched.some(record => record.meta.verifySave)
  //allow meta.public routes
  if (
    to.matched.some((record) => record.meta.public) &&
    !isBrowserButtonClicked
  ) {
    next();
  } else if (allowNav && !isBrowserButtonClicked) {
    next();
  }
  // condition for browser buttons
  else if (!DisabledBrowserButtonScreens && isBrowserButtonClicked) {
    isBrowserButtonClicked = false;
    let routeHistory = { to: to.name, from: from.name };
    store.commit("leftnav/updateNav", routeHistory);
    next();
  }
  // condition for collections,donorHub,productHub,CloudDashboard
  else if (DisabledBrowserButtonScreens && isBrowserButtonClicked) {
    isBrowserButtonClicked = false;
    next(false);
  }
  // TODO: Add support for checking saving verification on desired screens
  /* else if(SaveOptionScreen && isBrowserButtonClicked){
         isBrowserButtonClicked = false
         const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
         if (answer) {
             let routeHistory = { to:to.name,from:from.name }
             store.commit("leftnav/updateNav",routeHistory)
             next()
         } else {
             next(false)
         }
     }*/
});

router.afterEach(() => {
  store.commit("whlsecurity/setAllowNav", false);
});

export default router;
