import Vue from "vue";

const HEAP_INSTANCE_METHODS = [
  "addEventProperties",
  "addUserProperties",
  "clearEventProperties",
  "identify",
  "resetIdentity",
  "removeEventProperty",
  "setEventProperties",
  "track",
  "unsetEventProperty",
];

const VueHeap = {
  createHeap: function (key) {
    // initialize heap if necessary
    window.heap = window.heap || [];

    window.heap.appid = key;
    window.heap.config = {};

    Vue.prototype.$heap = {};
    Vue.prototype.$heap.appid = key;
    Vue.prototype.$heap.config = {};

    // while we are waiting for the heap script to load we still need to be
    // able to capture any events that are meant to be logged by heap. once the
    // script is loaded, these events will be uploaded to heap
    for (let i = 0; i < HEAP_INSTANCE_METHODS.length; i++) {
      const method = HEAP_INSTANCE_METHODS[i];
      window.heap[method] = function () {
        if (window.heap && window.heap.push) {
          window.heap.push(
            [method].concat(Array.prototype.slice.call(arguments, 0))
          );
        }
      };

      Vue.prototype.$heap[method] = (...args) => {
        if (window.heap[method]) {
          return window.heap[method](...args);
        }

        if (window.heap.push) {
          return window.heap.push([method, ...args]);
        }
      };
    }
  },
  addHeapScript: function (key) {
    const useHTTPS = "https:" === document.location.protocol;

    const heapScript = document.createElement("script");
    heapScript.type = "text/javascript";
    heapScript.async = false;
    heapScript.src = `${
      useHTTPS ? "https:" : "http:"
    }//cdn.heapanalytics.com/js/heap-${key}.js`;

    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(heapScript, firstScriptTag);
  },
  install: function (options) {
    const enable = options.enable;
    const heapKey = options.key;

    if (enable && (heapKey === undefined || heapKey === null)) {
      //eslint-disable-next-line no-console
      console.error("Heap was enabled but no heap key was specified.");
    }

    // needs to be created regardless so components don't error out
    this.createHeap(heapKey);

    if (options.enable) {
      this.addHeapScript(heapKey);
    }
  },
};

export default VueHeap;
