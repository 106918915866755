import Axios from "axios";

const USER = "/management/user/maintenance";
const ROLES = "/management/user/roles";
const GROUPS = "/management/user/groups";
const OVERRIDE_MESSAGE_ROLES = "/management/user/override-message-roles";
const SESSION = "/management/user/session";
const PICTURE = "/management/user/profile-picture";
const SYS_USER = "/collections/api/system-user";

export default {
  getAllUsers() {
    return Axios.get(USER);
  },
  getUsers(offset, pageSize, showInactive, search) {
    return Axios.get(
      USER +
        "?size=" +
        pageSize +
        "&page=" +
        offset +
        "&showInactive=" +
        showInactive +
        "&search=" +
        search
    );
  },
  getSystemUsers() {
    return Axios.get(SYS_USER);
  },
  addSystemUsers() {
    return Axios.post(SYS_USER);
  },
  addUser(payload) {
    return Axios.post(USER, payload);
  },
  deleteUser(item) {
    return Axios.delete(USER, {
      headers: {},
      data: item,
    });
  },
  getRoles() {
    return Axios.get(ROLES);
  },
  getGroups() {
    return Axios.get(GROUPS);
  },
  getOverrideMessageRoles() {
    return Axios.get(OVERRIDE_MESSAGE_ROLES);
  },
  deleteSession(item) {
    return Axios.delete(SESSION, {
      headers: {},
      data: item,
    });
  },
  deletePicture(item) {
    return Axios.delete(PICTURE, {
      headers: {},
      data: item,
    });
  },
};
