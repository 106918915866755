import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify, {
  Intersect,
  VApp,
  VAppBar,
  VAutocomplete,
  VAvatar,
  VBtn,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VCheckbox,
  VCol,
  VCombobox,
  VContainer,
  VDataTable,
  VDatePicker,
  VDivider,
  VDialog,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VFooter,
  VForm,
  VIcon,
  VImg,
  VInput,
  VList,
  VListItem,
  VListItemAvatar,
  VListItemContent,
  VListItemIcon,
  VListItemSubtitle,
  VListItemTitle,
  VMain,
  VMenu,
  VNavigationDrawer,
  VPagination,
  VProgressLinear,
  VRadio,
  VRadioGroup,
  VRow,
  VSelect,
  VSkeletonLoader,
  VSpacer,
  VSwitch,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTextarea,
  VTextField,
  VToolbar,
  VSheet,
  VChip,
  VChipGroup,
  VTooltip,
  ClickOutside,
} from "vuetify/lib";
import ClockIcon from "../assets/common-icons/ClockIcon.vue";
import LocationIcon from "../assets/common-icons/LocationIcon.vue";
import CalendarIcon from "../assets/common-icons/CalendarIcon.vue";
import RemoveIcon from "../assets/common-icons/RemoveIcon.vue";
import IndicatorIcon from "../assets/common-icons/IndicatorIcon.vue";
import OptionsIcon from "../assets/common-icons/OptionsIcon.vue";
import LogoutIcon from "../assets/common-icons/LogoutIcon.vue";
import MiscIcon from "../assets/common-icons/MiscIcon.vue";
import ErrorIcon from "../assets/common-icons/Error.vue";
import CheckIcon from "../assets/common-icons/Check.vue";
import WarningIcon from "@/assets/common-icons/WarningIcon";

Vue.use(Vuetify, {
  components: {
    VAutocomplete,
    VAvatar,
    VListItemTitle,
    VListItemAvatar,
    VDivider,
    VDialog,
    VListItem,
    VListItemIcon,
    VRow,
    VList,
    VCol,
    VAppBar,
    VImg,
    VNavigationDrawer,
    VMain,
    VApp,
    VContainer,
    VTextField,
    VForm,
    VRadioGroup,
    VRadio,
    VSelect,
    VMenu,
    VDatePicker,
    VDataTable,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VBtn,
    VSpacer,
    VPagination,
    VCombobox,
    VCheckbox,
    VIcon,
    VListItemContent,
    VListItemSubtitle,
    VFooter,
    VSkeletonLoader,
    VToolbar,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VTabs,
    VTab,
    VTabItem,
    VTabsItems,
    VSwitch,
    VProgressLinear,
    VTextarea,
    VInput,
    VSheet,
    VChip,
    VChipGroup,
    VTooltip,
  },
  directives: {
    Intersect,
    ClickOutside,
  },
});

export default new Vuetify({
  icons: {
    iconfont: "mdi",
    values: {
      clockIcon: {
        component: ClockIcon,
      },
      calendarIcon: {
        component: CalendarIcon,
      },
      removeIcon: {
        component: RemoveIcon,
      },
      locationIcon: {
        component: LocationIcon,
      },
      indicatorIcon: {
        component: IndicatorIcon,
      },
      logoutIcon: {
        component: LogoutIcon,
      },
      miscIcon: {
        component: MiscIcon,
      },
      optionsIcon: {
        component: OptionsIcon,
      },
      checkIcon: {
        component: CheckIcon,
      },
      errorIcon: {
        component: ErrorIcon,
      },
      warningIcon: {
        component: WarningIcon,
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: "#8AC417",
        secondary: "#404040",
        accent: "#479eea",
        disabled: "#8C8C8C",
        lines: "#DFE6E8",
        tags: "#EEF2F5",
        highlight: "#E2F0C4",
        grey: "#F9F9F9",
        active: "#C4E18A",
        error: "#D63C56",
        success: "#8AC417",
        warning: "#FB8C00"
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 601,
      sm: 1025,
      md: 1440,
      lg: 1920,
    },
  },
});
