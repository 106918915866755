export const whldonscrn = {
  namespaced: true,
  state: {
    webScreening: {
      donation: {},
      webVitalDtos: [],
      webScreeningTestResultDtos: [],
      histTechID: "",
      leftWithoutDonating: "N",
      drawDonor: "",
      overrideDrawDonor: false,
      completionStatus: false,
      leftArm: "", // stores pass (Y), fail (N), not inspected ("") value,
      rightArm: "",
      leftArmReason: "", // stores mnemonic code of a reason from the mnemonic tabl,
      rightArmReason: "",
    },
    backToScreening: {
      backToScreening: "false",
    },
  },
  actions: {},
  mutations: {
    updateWebScreening(state, data) {
      state.webScreening = { ...state.webScreening, ...data };
    },
    resetwebScreening(state) {
      state.webScreening = {
        donation: { supplyItemSet: [] },
        webVitalDtos: [],
        webScreeningTestResultDtos: [],
        arms: "",
        histTechID: "",
        leftWithoutDonating: "N",
        drawDonor: "",
        overrideDrawDonor: false,
        completionStatus: false,
        leftArm: "", // stores pass (Y), fail (N), not inspected ("") value,
        rightArm: "",
        leftArmReason: "", // stores mnemonic code of a reason from the mnemonic tabl,
        rightArmReason: "",
      };
    },
    updateBackToScreening(state, data) {
      state.backToScreening = { ...state.backToScreening, ...data };
    },
  },
};
