import Axios from "axios";

export default {
  data() {
    return {
      loadingRoles: false,
      authorityRoles: []
    };
  },
  mounted() {
    if (!this.authorityRoles.length) this.getRoles();
  },
  methods: {
    getRoles() {
      this.loadingRoles = true;
      Axios.get("/management/authorities/list-roles").then((res) => {
        if (res.data && res.data.length) {
          this.authorityRoles = res.data.map((role) =>
            role.authority.substring(5, role.authority.length)
          );
        } else this.authorityRoles = [];
        this.loadingRoles = false;
      });
    },
    $can(role) {
      let can = false;
      if (!role) return can;
      if (!this.authorityRoles.length) return can;
      if (this.authorityRoles.includes(role)) can = true;
      return can;
    }
  }
};
