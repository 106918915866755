export const whlmodreg = {
  namespaced: true,
  state: {
    modregDonation: {
      qpass: "",
      questionsRemaining: 0,
    },
    backFromModreg: false,
  },
  actions: {},
  mutations: {
    updatemodregDonation(state, data) {
      state.modregDonation = { ...state.modregDonation, ...data };
    },
    updateBackFromModreg(state, data) {
      state.backFromModreg = data;
    },
  },
};
