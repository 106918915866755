<template>
  <v-row justify="center" align="center">
    <v-col cols="12" class="mt-10 mx-auto">
      <center>
        <v-progress-circular
          :indeterminate="indeterminate"
          :rotate="rotate"
          :size="size"
          :width="width"
          color="primary"
        ></v-progress-circular>
      </center>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Loader",
  mixins: [],
  props: {
    width: {
      type: Number,
      default: 5,
    },
    size: {
      type: Number,
      default: 100,
    },
    indeterminate: {
      type: Boolean,
      default: true,
    },
  },
  directives: {},
  components: {},
  data() {
    return {
      rotate: 0,
    };
  },
  methods: {},
  computed: {},
};
</script>
