<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <slot name="icon" :on="on" :attrs="attrs">
        <img :src="InfoIcon" v-on="on" />
      </slot>
    </template>
    <v-list class="card">
      <v-subheader class="card-header"
        ><b>{{ this.header }} </b></v-subheader
      >
      <v-list-item v-for="item in this.items" :key="item">
        <v-list-item-content>
          {{ item }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-tooltip>
</template>
<script>
import InfoIcon from "@/assets/common-icons/info.png";
export default {
  name: "ToolTip",
  props: ["items", "header"],
  data() {
    return {
      InfoIcon: InfoIcon,
    };
  },
};
</script>

<style scoped>
.card {
  background-color: #f8f8f8;
}
.card-header {
  background-color: #f8f8f8;
}
</style>
