export const whlphlebot = {
  namespaced: true,
  state: {
    webPhlebotomy: {
      donation: { supplyItemSet: [] },
    },
    backToPhlebotomy: {
      backToPhlebotomy: "false",
    },
  },
  actions: {},
  mutations: {
    updateWebPhlebotomy(state, data) {
      state.webPhlebotomy = { ...state.webPhlebotomy, ...data };
    },
    resetWebPhlebotomy(state) {
      state.webPhlebotomy = {
        donation: { supplyItemSet: [] },
      };
    },
    updateBackToPhlebotomy(state, data) {
      state.backToPhlebotomy = { ...state.backToPhlebotomy, ...data };
    },
  },
};
