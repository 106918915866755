export const reqFunc = {
  namespaced: true,
  state: {
    func_id: 581,
    requestingDonation: {},
    unit: "",
  },
  actions: {
    requestFunctionUpdate({ commit }, data) {
      commit("updateRequestingFunction", data);
    },
    requestFunctionReset({ commit }) {
      commit("resetRequestingFunction");
    },
  },
  getters: {
    getRequestingFunction: (state) => {
      return state.func_id;
    },
    getRequestingDonation: (state) => {
      return state.requestingDonation;
    },
    getModRegUnit: (state) => {
      return state.unit;
    },
  },
  mutations: {
    updateRequestingFunction(state, data) {
      state.func_id = data.func_id;
      state.requestingDonation = data.requestingDonation;
    },
    resetRequestingFunction(state) {
      state.func_id = 581;
      state.requestingDonation = {};
    },
    updateModRegUnit(state, data) {
      state.unit = data;
    },
  },
};
