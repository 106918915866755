<template>
  <v-row :class="[{ modal: modal }, 'error-bar']" ref="error_bar">
    <v-expansion-panels
      flat
      v-model="panel"
      v-if="errors != null && errors.length > 0"
    >
      <v-expansion-panel class="panel" @change="contentPaneToggled">
        <v-expansion-panel-header
          class="errorHeader"
          :class="modal ? 'modalErrorHeader' : ''"
          disable-icon-rotate
          v-on:click="swapText"
        >
          {{ $t(lblerrmsg) }}
          <div class="rightLabel" id="errClose">{{ $t(lblclose) }}</div>
          <div class="rightLabel" id="errOpen" style="display: none">
            {{ $t(lblopen) }}
          </div>
          <template v-slot:actions>
            <v-icon id="errMinus" color="error">mdi-minus-circle</v-icon>
            <v-icon id="errPlus" color="error" style="display: none"
              >mdi-plus-circle</v-icon
            >
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="scrollable noPad">
          <div
            v-for="(error, key) in errors"
            :key="key++"
            class="underline subRow d-flex"
          >
            <div class="smallWidth" v-if="error.isOverridable">
              <common-button
                icon
                :id="'error-bar-edit'"
                @click="overrideError(error)"
              >
                <v-icon role="img" aria-hidden="false" class="gray-icon">
                  mdi-pencil
                </v-icon>
              </common-button>
            </div>
            <div class="smallWidth" v-else></div>
            <div class="messageText pr-4">{{ error.msg }}</div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import { mdiAccount } from "@mdi/js";
/*
    Error messages should come in the format of an Array of objects with the following properties:
    {
        msg: String used to display the error.
        isOverridable: Boolean, true to show overridable icon.
    }
 */
export default {
  name: "ErrorBar",
  props: {
    errors: [],
    modal: Boolean,
  },
  mixins: [],
  directives: {},
  components: {},
  data() {
    return {
      panel: 0,
      lblerrmsg: "messages.errmsgs",
      lblclose: "messages.lblclose",
      lblopen: "messages.lblopen",
      showClose: true,
      icons: {
        mdiAccount,
      },
    };
  },
  methods: {
    swapText() {
      if (this.showClose) {
        this.showOpenHideClose();
        this.showClose = false;
      } else {
        this.hideOpenShowClose();
        this.showClose = true;
      }
    },
    bringErrorsIntoView() {
      const ele = this.$refs["error_bar"];

      if (ele) {
        setTimeout(() => {
          ele.scrollIntoView({ behavior: "smooth", block: "end" });
        }, 200);
      }
    },
    contentPaneToggled() {
      this.bringErrorsIntoView();
    },
    hideOpenShowClose() {
      document.getElementById("errOpen").style.display = "none";
      document.getElementById("errClose").style.display = "block";
      document.getElementById("errMinus").style.display = "block";
      document.getElementById("errPlus").style.display = "none";
    },
    showOpenHideClose() {
      document.getElementById("errOpen").style.display = "block";
      document.getElementById("errClose").style.display = "none";
      document.getElementById("errMinus").style.display = "none";
      document.getElementById("errPlus").style.display = "block";
    },
    overrideError(error) {
      this.$emit("override", error);
    },
  },
  watch: {
    errors() {
      if (!this.modal) {
        return;
      }

      this.bringErrorsIntoView();
    },
  },
  computed: {},
  mounted() {
    if (!this.modal) {
      return;
    }

    this.bringErrorsIntoView();
  },
};
</script>

<style scoped>
.row {
  padding: 0 !important;
  margin-top: 0 !important;
}

.messageText {
  padding-left: 1%;
  width: 95%;
  display: inline;
  height: auto;
  margin: auto 0;
}

.smallWidth {
  min-width: 38px;
  min-height: 36px;
  border-right: silver 1px solid;
  border-left: silver 1px solid;
  height: auto;
}

.errorHeader {
  background-color: var(--mbl-error) !important;
}

.modalErrorHeader {
  border-radius: 0px;
}

.subRow:nth-child(even) {
  background-color: var(--mbl-alternate-dark-box);
}

.subRow {
  border-bottom: silver 1px solid;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  border-right: silver 1px solid;
}

.override {
  height: 15px;
  width: auto;
  margin-top: 4px;
  margin-left: 6px;
}

.scrollable {
  margin: 0px;
  padding: 0px !important;
  max-height: 125px;
  overflow: auto;
  border-bottom: silver 2px solid;
}

.noPad {
  padding: 0px !important;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

.v-expansion-panel >>> .v-expansion-panel-header {
  min-height: 30px !important;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: silver 1px solid;
}

.rightLabel {
  text-align: right;
  padding-right: 3px;
}

.gray-icon {
  color: var(--text-color) !important;
}

.error-bar {
  margin-left: 0;
}

.error-bar.modal {
  margin-left: 0px !important;
  margin-right: 0px !important;
  box-shadow: none;
  width: 100%;
}
</style>
