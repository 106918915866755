export const fielderr = {
  namespaced: true,
  state: {
    sharedFieldErrors: { contact: {} },
  },
  actions: {
    doSetError(context, errors) {
      context.commit("setError", errors);
    },
  },
  mutations: {
    setError(state, errors) {
      state.sharedFieldErrors = { ...errors };
    },
  },
  getters: {
    getFieldErrors(state) {
      return state.sharedFieldErrors;
    },
  },
};
