import PatientManagementService from "@/modules/patient/management/services/PatientManagement.service.js";
import router from "@/router";
import moment from "moment";

export const PatientManagement = {
  namespaced: true,
  state: {
    mutextMap: new Map(),
  },
  mutations: {
    setMutextRefreshIntervalID(state, mutex) {
      state.mutextMap.set(mutex.personUuid, mutex.id);
    },
    clearMutextMap(state) {
      state.mutextMap.clear();
    },
  },
  actions: {
    acquirePersonMutex(context, personUuid) {
      return new Promise((resolve, reject) => {
        PatientManagementService.acquirePersonMutex(personUuid)
          .then((result) => {
            if (!context.state.mutextMap.has(personUuid)) {
              context.dispatch("startMutexRefresh", result.data);
            }
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    startMutexRefresh(context, personMutex) {
      let now = new moment();
      let expire = new moment(personMutex.expires);
      let diffMs = expire - now;
      let refreshPeriod = diffMs * 0.8;
      const intervalID = setInterval(() => {
        PatientManagementService.refreshPersonMutex(
          personMutex.personUuid
        ).catch((error) => {
          context.dispatch("stopMutexRefresh", personMutex.personUuid);
          //if 401 logout
          if (error.response && error.response.status === 401) {
            context.dispatch("auth/logout", null, { root: true });
            context.commit("whlsecurity/setAllowNav", true, { root: true });
            router.push({ name: "logged-out" });
          }
        });
      }, refreshPeriod);

      context.commit("setMutextRefreshIntervalID", {
        id: intervalID,
        personUuid: personMutex.personUuid,
      });
    },
    stopMutexRefresh({ state }, personUuid) {
      let id = state.mutextMap.get(personUuid);
      clearInterval(id);
      state.mutextMap.delete(personUuid);
    },
    releasePersonMutex(context, personUuid) {
      context.dispatch("stopMutexRefresh", personUuid);
      return PatientManagementService.releasePersonMutex(personUuid);
    },
  },
};
