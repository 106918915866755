<template>
  <v-footer app padless class="app-footer">
    <v-row>
      <v-col cols="4" id="footer-current-page" class="footer-text text-left">{{
        getCurrentPage
      }}</v-col>
      <v-col cols="4" id="footer-rights" class="footer-text text-center"
        >© BBCS 2021 All Rights Reserved</v-col
      >
      <v-col cols="4" id="footer-environment" class="footer-text text-right"
        >{{ getRealm }} Environment • Version
        {{ getVersion }}
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppFooter",
  data() {
    return {
      loading: false,
      errored: false,
      message: "",
    };
  },
  methods: {
    ...mapGetters("leftnav", ["getMyState"]),
  },
  computed: {
    getRealm() {
      if (sessionStorage.getItem("realm")) {
        return sessionStorage.getItem("realm");
      } else {
        return "";
      }
    },
    getCurrentPage() {
      let key = this.$route.meta["titleKey"];
      return !key ? this.$t("default-footer") : this.$t(key);
    },
    getVersion() {
      return this.$version.major + "." + this.$version.minor;
    },
  },
};
</script>

<style scoped>
.app-footer {
  background-color: #eef2f5;
  height: 40px;
  padding: 0 30px;
  font-size: 14px;
}

.footer-text {
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.48px;
  color: #6c7885;
  padding: 0;
}
</style>
