import Axios from "axios";
import axios from "axios";

const PATH = "/collections/api/mbldnrmgmt";
const APPOINTMENTS = "/collections/api/apptmgmt";
const getDriveStatus = "/get-drive-status";
const getActivityQueueItems =
  "/collections/api/mbldnrmgmt/donor-activity-items/";

const QuestionsCompleted = "/collections/api/mbldnrmgmt/isQuestionsCompleted";
const PersonMutexAcquire = "/collections/api/whlpersonlock/acquire";
const PersonMutexRelease = "/collections/api/whlpersonlock/release";
const PersonMutexRefresh = "/collections/api/whlpersonlock/refresh";
const PersonMutexOverrideRelease =
  "/collections/api/whlpersonlock/override-release";
const PersonMutexInUse = "/collections/api/whlpersonlock/is-in-use";
const DTSEQ = "/collections/api/mbldnrmgmt/get-dtseq-sequence";
const getCollectionsSources = "/collections/api/mbldnrmgmt/collections-sources";

const updateAppointmentStatus = "/update-status";
const updateDonTranStatus = "/updateTranStatus";
const getDonorByQuickPass = "/get-donor-by-quickpass";
const PhlebotomyEnter = "/valid-phlebotomy-enter";
export default {
  isQuestionsCompleted(appearance) {
    return Axios.get(QuestionsCompleted, {
      params: { appearance: appearance },
    });
  },
  //Use whldnrmgmt store action instead
  acquirePersonMutex(personUuid) {
    return Axios.post(PersonMutexAcquire, {
      personUuid: personUuid,
    });
  },
  refreshPersonMutex(personUuid) {
    return Axios.post(PersonMutexRefresh, {
      personUuid: personUuid,
    });
  },
  overrideReleaseMutex(personUuid, func, token) {
    return Axios.post(PersonMutexOverrideRelease, {
      personUuid: personUuid,
      func: func,
      mutexToken: token,
    });
  },
  //Use whldnrmgmt store action instead
  releasePersonMutex(personUuid) {
    return Axios.post(PersonMutexRelease, {
      personUuid: personUuid,
    });
  },
  isInUse(personUuid) {
    return Axios.post(PersonMutexInUse, {
      personUuid: personUuid,
    });
  },
  getDTSEQ(unit) {
    return Axios.get(DTSEQ, {
      params: { unit: unit },
    });
  },
  validatePhlebotomyEnter(appearanceUuid) {
    return Axios.get(PATH + PhlebotomyEnter, {
      params: { appearanceUuid: appearanceUuid },
    });
  },

  getDriveStatus(source) {
    return Axios.get(PATH + getDriveStatus, {
      params: {
        source: source,
      },
    });
  },

  updateAppointmentStatus(dto) {
    return Axios.post(APPOINTMENTS + updateAppointmentStatus, dto);
  },
  updateDonTranStatus(status, appearanceUuid) {
    return axios.patch(
      PATH + updateDonTranStatus,
      {},
      {
        params: {
          status: status,
          appearanceUuid: appearanceUuid,
        },
      }
    );
  },
  getDonorByQuickPass(quickpassString) {
    return Axios.post(PATH + getDonorByQuickPass, quickpassString, {
      headers: {
        "Content-type": "application/json",
      },
    });
  },
  editDonorScan(scan, location) {
    return Axios.post(
      PATH + "/editDonorScan",
      {},
      {
        headers: {
          "Content-type": "application/json",
        },
        params: {
          scan: scan,
          location: location,
        },
      }
    );
  },
  editUnitScan(scan, location) {
    return Axios.get(PATH + "/edit-unit-scan", {
      params: {
        scan: scan,
        location: location,
      },
    });
  },
  getActivityQueueItems(source) {
    return Axios.get(getActivityQueueItems + source);
  },

  getCollectionsSources() {
    return Axios.get(getCollectionsSources);
  },
};
