const initialState = false;
const helper = {
  findNameByRoutePath(tupule) {
    let res = "Name Not Found";

    if (tupule.location.name) {
      return tupule.location.name;
    }
    let local = tupule.location;
    if (tupule.location.path) {
      local = tupule.location.path;
    }

    tupule.router.options.routes.forEach((route) => {
      if (route.path.toLowerCase() === local.toLowerCase()) {
        res = route.name;
      }
    });

    return res;
  },
  fixRoutePathIfDuplicate(local, res) {
    let seen = [];
    for (let i = 0; i < local.length; ++i) {
      if (seen.includes(local[i])) {
        return local.splice(0, local.indexOf(res) + 1);
      } else {
        seen.push(local[i]);
      }
    }
    return local;
  },
};

export const leftnav = {
  namespaced: true,
  state: {
    open: initialState,
    path: [],
    donorDemoNext: true,
  },
  getters: {
    currentstate: (state) => {
      return state.open;
    },
    getNav: (state) => {
      let res = [];
      if (state.path.length > 0) {
        for (let i = 0; i < state.path.length; i++) {
          // Condition to bold the last element of breadcrumb.
          if (i == state.path.length - 1) {
            if (state.path[i] !== "Cloud Dashboard") {
              res.push({
                text: state.path[i],
                disabled: true,
                href: "",
                lastElement: true,
              });
            }
          } else {
            if (state.path[i] !== "Cloud Dashboard") {
              res.push({
                text: state.path[i],
                disabled: true,
                href: "",
                lastElement: false,
              });
            }
          }
        }
      }
      return res;
    },
    getMyState: (state) => state.path,
  },
  actions: {
    toggleNav({ commit }) {
      commit("editNav");
    },
  },
  computed: {
    leftNavState() {
      return this.$store.getters.currentstate;
    },
    currentNav() {
      return this.$store.state.path;
    },
  },
  mutations: {
    setDonorDemoNext(state, value) {
      state.donorDemoNext = value;
    },
    editNav(state) {
      state.open = !state.open;
    },
    clearPath(state) {
      state.path = [];
    },
    replacePath(state, tuple) {
      let res = helper.findNameByRoutePath(tuple);
      let local = JSON.parse(JSON.stringify(state.path));

      local[local.length - 1] = res;
      state.path = helper.fixRoutePathIfDuplicate(local, res);
    },
    appendPath(state, tuple) {
      let res = "";
      if (typeof tuple === "string") {
        res = tuple;
      } else {
        res = helper.findNameByRoutePath(tuple);
      }
      let local = JSON.parse(JSON.stringify(state.path));

      local.push(res);
      state.path = helper.fixRoutePathIfDuplicate(local, res);
    },
    backPath(state, amount) {
      let local = JSON.parse(JSON.stringify(state.path));
      for (let i = 0; i < amount; i++) {
        local.pop();
      }
      state.path = local;
    },

    updateNav(state, routeHistory) {
      let local = state.path;
      if (!local.includes(routeHistory.to)) {
        local.push(routeHistory.to);
      } else {
        local.pop();
      }
      state.path = local;
    },
  },
};
