<template>
  <component :is="layout">
    <BlockUI :message="msg" v-if="show">
      <v-progress-circular
        :indeterminate="indeterminate"
        :rotate="rotate"
        :size="size"
        :width="width"
        color="light-blue"
      ></v-progress-circular>
    </BlockUI>
    <router-view
      :key="$route.path"
      @show-veil="showVeil"
      @hide-veil="hideVeil"
      @app-error="appError"
    />
    <ProceedOrCancel
      v-if="proceedOrCancel"
      @close="closePOCE"
      :text="recoveryMessage"
      @clicked="poceOption"
      :dialogType="dialogType"
    />
    <Toast ref="toast" />
    <ModalIdle
      v-on:close="
        () => {
          this.inactivityTime();
        }
      "
      v-if="isIdle"
    />
  </component>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { ConfigurationService } from "@bbcsinc/common-vue";
import Toast from "@/modules/main/components/Toast";
import ModalIdle from "@/components/ModalIdle";
import UserRoles from "@/mixins/UserRoles";

const default_layout = "default";
export default {
  mixins: [UserRoles],
  components: {
    Toast,
    ModalIdle,
  },
  data() {
    return {
      show: false,
      msg: "",
      defaultMessage: "whlmain.veil.defaultMessage",
      indeterminate: true,
      rotate: 0,
      size: 100,
      width: 10,
      proceedOrCancel: false,
      error: "none",
      defaultRecoveryMessage: "messages.0401",
      recoveryMessage: "",
      IDLE_TIMEOUT: 30 * 1000, //defauls IDLE_TIMEOUT 30000 milliseconds
      isTimedOut: false,
      time: 0,
    };
  },
  methods: {
    ...mapMutations("DonorManagement", ["setMutexRefreshFail"]),
    ...mapMutations("productStore", ["setLaboratoryLocation"]),
    // ...mapActions("auth", ["loadCurrentUser"]),
    appError(payload) {
      if (payload) {
        this.recoveryMessage = payload.msg
          ? payload.msg
          : this.$t(this.defaultRecoveryMessage);
        this.error = payload.level ? payload.level : "recoverable";
        this.proceedOrCancel = true;
      }
    },
    closePOCE() {
      this.proceedOrCancel = false;
    },
    poceOption(value) {
      if (value.toUpperCase() === "OK" || value.toUpperCase() === "NO") {
        this.proceedOrCancel = false;
      }
    },
    showVeil(payload) {
      this.show = true;
      if (payload != undefined) {
        this.msg = payload.msg;
      } else {
        this.msg = this.$t(this.defaultMessage);
      }
    },
    hideVeil() {
      this.show = false;
      this.msg = "";
    },
    initStore() {
      let location = ConfigurationService.getConfigByKey("DFTLOC");
      this.setLaboratoryLocation(location);
      // this.loadCurrentUser();
    },
    inactivityTime() {
      this.isTimedOut = false;
      window.onload = this.resetTimer;

      document.onmousemove = this.resetTimer;
      document.onkeydown = this.resetTimer;
      document.onmousedown = this.resetTimer;
      document.onclick = this.resetTimer;
      document.onkeydown = this.resetTimer;
      document.addEventListener("scroll", this.resetTimer(), true);
    },
    resetTimer() {
      clearTimeout(this.time);
      if (
        ConfigurationService.getConfigByKey("IDLETIMEOUT") &&
        ConfigurationService.getConfigByKey("IDLETIMEOUTWARNING")
      ) {
        var _diff =
          ConfigurationService.getConfigByKey("IDLETIMEOUT") -
          ConfigurationService.getConfigByKey("IDLETIMEOUTWARNING");
        if (_diff > 0) {
          this.IDLE_TIMEOUT = _diff * 60 * 1000;
        }
      }
      this.time = setTimeout(this.showLogoutWarning, this.IDLE_TIMEOUT); //this need to be in milliseconds
    },
    showLogoutWarning() {
      this.isTimedOut = true;
    },
  },

  computed: {
    ...mapGetters("DonorManagement", ["mutexRefreshFail"]),
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    severe() {
      return this.error === "severe";
    },
    recoverable() {
      return this.error === "recoverable";
    },
    mutexRefreshFailed() {
      return this.mutexRefreshFail;
    },
    dialogType() {
      return this.severe ? "OK" : "";
    },
    isIdle() {
      return this.isTimedOut;
    },
  },
  watch: {
    $route: function () {
      this.hideVeil(); //make sure veil is always hidden after routing.
    },
    mutexRefreshFailed: function (failed) {
      if (failed) {
        this.proceedOrCancel = true;
        this.recoveryMessage = this.$t("messages.0403");
        this.error = "severe";
        this.setMutexRefreshFail(false);
      }
    },
  },
  created() {
    this.initStore();
  },
  mounted() {
    this.$root.toast = this.$refs.toast;
    this.inactivityTime();
  },
};
</script>
<style lang="scss">
@import "../src/styles/css/fonts.css";
@import "../src/styles/css/base.scss";
@import "../src/styles/scss/typography.scss";
@import "../src/styles/css/form-overrides.css";
@import "../src/styles/css/common-components.css";

.v-toolbar__content {
  padding: 0;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #eef2f5;
}
::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background-color: #d6d6d6;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--primary);
}
</style>
